export const fastColumns = [
    {
      type: 'selection',
      width: 50,
      fixed: 'left'
    },
    {
      prop: 'index',
      label: '序号',
      width: 50,
      sortable: true,
      hiddenSearch: true,
      slotName: 'index'
    },
    {
      prop: 'batchNumber',
      label: '箱码',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'materialsSpecifications',
      label: '规格型号',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'firstLocation',
      label: '一级位置对象',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'firstLocationNumber',
      label: '一级位置单号',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'secondLocation',
      label: '二级位置对象',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'secondLocationNumber',
      label: '二级位置单号',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'locationUpdateTime',
      label: '位置更新时间',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'quantity',
      label: '数量',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'materialsUnit',
      label: '单位',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'status',
      label: '状态',
      sortable: false,
      hiddenSearch: true,
      slotName: 'status'
    },
    {
      prop: 'creator',
      label: '创建人',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'createTime',
      label: '创建时间',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'updater',
      label: '更新人',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'lastUpdateTime',
      label: '更新时间',
      sortable: false,
      hiddenSearch: true
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 150
    }
  ]
  