export const columns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'welderName',
    label: '姓名',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'welderNumber',
    label: '焊工编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'enableDate',
    label: '启用日期',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'welderProjectName',
    label: '合格项目',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'issueDate',
    label: '发证日期',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'expirationDate',
    label: '到期日期',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    slotName: 'action',
    width: 160,
    fixed: 'right'
  }
]

export const columns2 = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'number',
    label: '项目编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'name',
    label: '项目名称',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 160,
    slotName: 'action',
    fixed: 'right'
  }
]

export const columns3 = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'name',
    label: '焊工姓名',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'userName',
    label: '用户名',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'organization',
    label: '所在组织',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'number',
    label: '焊工编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'enableDate',
    label: '启用日期',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'endDate',
    label: '结束日期',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 160,
    slotName: 'action',
    fixed: 'right'
  }
]
// 变更记录
export const changeLogColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'record',
    label: '变更内容',
    sortable: false,
    hiddenSearch: true,
    slotName: 'record'
  },
  {
    prop: 'changeUserName',
    label: '变更人',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    label: '变更时间',
    sortable: false,
    prop: 'lastUpdateTime',
    hiddenSearch: true,
    width: 160
  }
]