<template>
  <div>
    <!-- 工艺路线-->
    <div v-if="type ==='process'">
      <div v-if="data.processFlowVersionId">
        <div class="process-info">
          工艺路线：{{ baseFormData.name }}
          <el-tag size="small">{{ processFlowVersion.version }}</el-tag>
        </div>

        <div class="process-detail-body">
          <div class="detail-card">
            <div class="card-tit">基本信息</div>
            <div class="card-bot">
              <el-row class="view-list" :gutter="24">
                <!--            暂时这么处理距离问题-->
                <el-col
                  v-for="(item, index) in columns.base"
                  :key="item.prop"
                  class="ellipsis"
                  :title="getValue(item)"
                  :span="6"
                  :style="{marginBottom: index !== 4 ? '20px' : 0 }"
                >
                  <span>{{ item.label }}：</span>
                  <span>{{ getValue(item) }}</span>
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="detail-card" style="margin-top: 10px">
            <div class="card-tit">工艺路线图</div>
            <div class="card-bot process-card">
              <ProcessFlow ref="processFlow" :read-only="true" :concise-process-flow="true"
                           :process-flow-detail="processFlowVersion"/>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="padding-top: 50px">
        <MBlank title="请前往物料配置配置工艺！"/>
      </div>
    </div>
    <!--  图纸及附件-->
    <div v-if="type ==='drawAndFile'">
      <div v-if="(memberType === 'mom' && currentType != 'add') || (memberType === 'line' && isShowDrawAndFile)"
           class="detail-card">
        <div v-if="mCode === 'fsd'" class="card-bot">
          <div class="b20 flex-sbc">
            <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">图纸</span>
          </div>
          <br>
          <MTable
            ref="mTable"
            :show-page="false"
            :height="300"
            :columns="memberType === 'mom' ? columns.momFiles : columns.lineFiles"
            :data="fsdWlList"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              {{ row.fname }}
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
            <div slot="action" slot-scope="{ row }">
              <div style="cursor:pointer;color: #607FFF;font-weight: bold;" @click="toUrl(row)">查看</div>
            </div>
          </MTable>

          <div class="bt20 flex-sbc">
            <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">其他附件</span>
          </div>
          <br>
          <el-upload
            v-if="data.id && memberType === 'mom'"
            class="b20"
            type="primary"
            v-bind="uploadProps"
          >
            <el-button size="small" type="primary" @click="uploadKey = 'filesList'">上传附件</el-button>
          </el-upload>
          <MTable
            ref="mTable"
            :show-page="false"
            :height="300"
            :columns="memberType === 'mom' ? columns.momFiles : columns.lineFiles"
            :data="data.attachment ? JSON.parse(data.attachment) : []"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a href="javascript:void(0)"
                 @click="openUrlGlobal(row.url)" :download="row.name" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
            <div slot="action" slot-scope="{ row }">
              <DelPopover @onOk="(callback) => delFiles('filesList', row, callback)"/>
            </div>
          </MTable>
        </div>
        <div v-else class="card-bot">
          <div class="b20 flex-sbc">
            <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">图纸</span>
          </div>
          <br>
          <el-upload
            v-if="data.id && memberType === 'mom'"
            class="b20"
            type="primary"
            v-bind="uploadProps"
          >
            <el-button size="small" type="primary" @click="uploadKey = 'drawingsList'">上传图纸</el-button>
          </el-upload>
          <MTable
            ref="mTable"
            :show-page="false"
            :height="300"
            :columns="memberType === 'mom' ? columns.momFiles : columns.lineFiles"
            :data="data.drawing ? JSON.parse(data.drawing) : []"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a href="javascript:void(0)"
                 @click="openUrlGlobal(row.url)" :download="row.name" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
            <div slot="action" slot-scope="{ row }">
              <DelPopover @onOk="(callback) => delFiles('drawingsList', row, callback)"/>
            </div>
          </MTable>

          <div class="bt20 flex-sbc">
            <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">其他附件</span>
          </div>
          <br>
          <el-upload
            v-if="data.id && memberType === 'mom'"
            class="b20"
            type="primary"
            v-bind="uploadProps"
          >
            <el-button size="small" type="primary" @click="uploadKey = 'filesList'">上传附件</el-button>
          </el-upload>
          <MTable
            ref="mTable"
            :show-page="false"
            :height="300"
            :columns="memberType === 'mom' ? columns.momFiles : columns.lineFiles"
            :data="data.attachment ? JSON.parse(data.attachment) : []"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a href="javascript:void(0)"
                 @click="openUrlGlobal(row.url)" :download="row.name" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
            <div slot="action" slot-scope="{ row }">
              <DelPopover @onOk="(callback) => delFiles('filesList', row, callback)"/>
            </div>
          </MTable>
        </div>
      </div>
      <div v-else style="padding-top: 50px">
        <MBlank title="请前往物料配置配置图纸及附件！"/>
      </div>
    </div>
  </div>
</template>

<script>
import {base} from '@/views/information/production/process/detail/columns'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import processApi from '@/api/information/production/process'
import materialsApi from '@/api/information/materials'
import {getToken} from '@/utils/auth'
import DelPopover from '@/components/DelPopover/index'
import axios from "axios";

export default {
  name: 'StaticInfo',
  components: {ProcessFlow, DelPopover},
  props: {
    type: {
      type: String,
      required: true,
      default: ''
    },
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    memberType: {
      type: String,
      default: 'mom'
    },
    currentType: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      fsdToken: '',
      fsdWlList: [],
      uploadKey: 'drawingsList',
      baseFormData: {},
      processFlowVersion: {},
      mCode: '',
      columns: {
        base,
        momFiles: [
          {
            prop: 'index',
            label: '序号',
            slotName: 'index',
            width: 80,
            hiddenSearch: true
          },
          {
            prop: 'name',
            label: '名称',
            slotName: 'fileName',
            sortable: false,
            hiddenSearch: true
          },
          {
            slotName: 'action',
            label: '操作',
            fixed: 'right',
            hiddenSearch: true,
            sortable: false
          }],
        lineFiles: [
          {
            prop: 'index',
            label: '序号',
            slotName: 'index',
            width: 80,
            hiddenSearch: true
          },
          {
            prop: 'name',
            label: '名称',
            slotName: 'fileName',
            sortable: false,
            hiddenSearch: true
          }]
      },
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: {token: getToken()},
        showFileList: false,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        beforeUpload: this.beforeUpload
      },
    }
  },
  computed: {
    isShowDrawAndFile() {
      const drawFlag = this.data.drawing && JSON.parse(this.data.drawing).length
      const attachmentFlag = this.data.attachment && JSON.parse(this.data.attachment).length
      return drawFlag || attachmentFlag
    }
  },
  created() {
    this.mCode = localStorage.getItem('memberCode')
    if (this.type === 'drawAndFile' && this.currentType != 'add' && this.mCode === 'fsd') {
      axios.get('http://10.100.100.207:8080/sipmweb/api/oauth?uname=MES&passwd=192c2f18b95d9be2c0c7fbb07e4d6068&f=true')
        .then(res => {
          if (res.status === 200) {
            this.fsdToken = res.data.errmsg

            // 获取物料
            // 获取物料列表
            let srcWlUrl = 'http://10.100.100.207:8080/sipmweb/api/' + this.fsdToken + '/search/DESF2/filter?sort=1&start=0&size=50'
            srcWlUrl += '&filter=' + encodeURIComponent("NO='"+ this.data.code +"'" + " and STATE='A'")
            // srcWlUrl += '&filter=' + encodeURIComponent("NO='KQX186.000' and STATE='A'")
            axios.get(srcWlUrl)
              .then(res2 => {
                if (res2.status === 200) {
                  if (res2.data.errcode === 0) {
                    this.fsdWlList = res2.data.list
                    console.log('fsdWlList', this.fsdWlList)
                  }
                }
              })
          }
        })
    }
  },
  mounted() {
    console.log('data', this.data)
    if (this.type === 'process' && this.data.processFlowVersionId) {
      this.getProcessVersionDetail()
    }
  },
  methods: {
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    // 上传图纸/附件
    async fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        const current = await materialsApi.getMaterialsDetailList(this.data.id)
        if (current) {
          const data = {
            drawingsList: current.drawing ? JSON.parse(current.drawing) : [],
            filesList: current.attachment ? JSON.parse(current.attachment) : []
          }
          data[this.uploadKey].push({
            id: file.uid,
            name: file.name,
            url: res.result
          })
          const materials = await materialsApi.modifyMaterials({
            ...this.createResetData(),
            drawing: JSON.stringify(data.drawingsList || []),
            attachment: JSON.stringify(data.filesList || [])
          })
          if (materials) {
            this.data.drawing = JSON.stringify(data.drawingsList || [])
            this.data.attachment = JSON.stringify(data.filesList || [])
            this.$message.success('上传成功')
          }
        }
      } else {
        this.$message.error(res.result)
      }
    },
    // 删除图纸及附件
    async delFiles(key, row, callback) {
      const current = await materialsApi.getMaterialsDetailList(this.data.id)
      if (current) {
        const data = {
          drawingsList: current.drawing ? JSON.parse(current.drawing) : [],
          filesList: current.attachment ? JSON.parse(current.attachment) : []
        }
        const currentIndex = data[key].findIndex(item => item.id === row.id)
        data[key].splice(currentIndex, 1)
        const res = await materialsApi.modifyMaterials({
          ...this.createResetData(),
          drawing: JSON.stringify(data.drawingsList || []),
          attachment: JSON.stringify(data.filesList || [])
        })
        if (res) {
          this.data.drawing = JSON.stringify(data.drawingsList || [])
          this.data.attachment = JSON.stringify(data.filesList || [])
          this.$message.success('删除成功')
          callback()
        }
      }
    },
    createResetData() {
      const raw = this.data
      return {
        id: raw.id,
        name: raw.name,
        code: raw.code,
        specifications: raw.specifications,
        mainUnitId: raw.mainUnitId,
        mainUnitName: raw.mainUnitName,
        materialsClassId: raw.materialsClassId,
        materialsClassName: raw.materialsClassName,
        materialsTypeId: raw.materialsTypeId,
        materialsTypeName: raw.materialsTypeName
      }
    },
    async getProcessVersionDetail() {
      const res = await processApi.getProcessDetail({
        id: this.data.processFlowId,
        versionId: this.data.processFlowVersionId
      })
      if (res) {
        this.baseFormData = res
        this.processFlowVersion = res.processFlowVersion
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && row.prop !== 'areas') {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    toUrl(item) {
      const t = this.fsdToken
      axios.post('http://10.100.100.125:8011/api/mes/file-ecrypt', {
          id: item.objId,
          rid: t
        }
      ).then(res => {
        if (res.status === 200) {
          // window.open(res.data)
          const a = encodeURIComponent(res.data)
          window.open('/pdf-preview/web/viewer.html?file=' + a)
        }
      })
      console.log(item)
    }
  }
}
</script>

<style scoped lang="scss">
.card-bot {
  padding: 20px 0 0 0;
}

.process-info {
  display: flex;
  align-items: center;

  .el-tag {
    border-color: #DDE3EB;
    background: #F6F7F9;
    color: #9597AE;
    margin: 0 20px;
  }

  button {
    img {
      width: 16px;
      position: relative;
      top: 1px;
    }
  }
}
</style>
