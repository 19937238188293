<template>
  <div class="materials-setting-wrap">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button v-if="permission('bomList')" label="bom">BOM</el-radio-button>
      <el-radio-button v-if="permission('processList')" label="process">工艺</el-radio-button>
      <el-radio-button v-if="permission('drawAndFileList')" label="drawAndFile">图纸及附件</el-radio-button>
      <el-radio-button v-if="permission('inspectionProjectGroupProcessList')" label="inspectionProjectGroupProcess">过程检</el-radio-button>
      <el-radio-button v-if="permission('inspectionInComingList')" label="inspectionInComing">{{memberCode==='dmdz'?'放行单':'来料检'}} </el-radio-button>
      <el-radio-button v-if="permission('inspectionOutboundList')" label="inspectionOutbound">出货检</el-radio-button>
      <!--      <el-radio-button v-if="permission('inspectionProjectGroupFirstList')" label="inspectionProjectGroupFirst">首检</el-radio-button>-->
    </el-radio-group>
    <div v-if="permission(params.radio + 'List')" class="top">
      <SearchForm ref="searchForm" :immediately-query="true" :default-form="searchFormData" :form-list-extend="params.radio === 'bom' ? searchFormList : searchFormLists">
        <m-remote-select
          slot="prepend"
          v-model="params.materialsId"
          class="input-prepend"
          placeholder="请选择物料"
          :interface-fun="materialsApi.getMaterialsListPage"
          :condition="baseCondition"
          :options.sync="formOptions.materialsList"
          :clearable="false"
          @on-change="materialsChange"
        />
        <el-button slot="append" icon="el-icon-more" @click="selectMaterials" />
        <div slot="prepends" style="height: 32px" />
        <el-button slot="prepends" size="small" type="primary" @click="getBom">确定</el-button>
      </SearchForm>
      <div v-if="params.materialsId" class="material-font">
        <div style="padding-right: 20px">物料编码：{{ currentMaterials('code') }}</div>
        <div style="padding-right: 20px">物料名称：{{ currentMaterials('name') }}</div>
        <div style="padding-right: 20px">物料规格：{{ currentMaterials('specifications') }}</div>
      </div>
    </div>
    <div class="bottom" :style="{ maxHeight: height + 'px'}">
      <!--      bom-->
      <div v-if="params.radio === 'bom' && params.materialsId">
        <FunctionHeader
          :export-name="functionHeader.exportName"
          :import-name="functionHeader.importName"
          :hidden-search="true"
          :export-params="searchData"
        >
          <div slot="all">
            <el-button size="small" type="primary" style="margin-left: 15px;" @click="getAll">{{ isFirst ? '折叠全部' : '展开全部' }}</el-button>
          </div>
        </FunctionHeader>
        <MTable
          ref="mTable"
          :only-key="'uuid'"
          :is-tree-data="true"
          :columns="columnsAll"
          :height="heights"
          :show-page="false"
          :data="list"
          :columns-setting="false"
          :tree-props="{children: 'childBomList', hasChildren: 'hasChildren'}"
        >
          >
          <div slot="index" slot-scope="{ row,column, $index }">{{ $index + 1 }}</div>
          <div slot="quantityStandard" slot-scope="{ row }">{{ fixData(row.quantityStandard) }}</div>
          <div slot="action" slot-scope="{ row }">
            <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row)">编辑</el-button>
          </div>
        </MTable>
      </div>
      <!--      工艺路线-->
      <div v-if="params.radio === 'process'">
        <el-button v-if="permission('SetProcess')" type="primary" class="b10" :disabled="!!processFlowVersion" @click="selectProcessFlow">
          选择工艺路线
        </el-button>

        <div v-if="processFlowVersion" class="process-info">
          工艺路线：{{ baseFormData.name }}
          <el-tag size="small">{{ processFlowVersion.version }}</el-tag>
          <el-button v-if="permission('DelProcess')" type="text" @click="delProcess">
            <img src="@/assets/l_c_M_images/垃圾桶.png" alt="">
          </el-button>
        </div>

        <div v-if="processFlowVersion" class="process-detail-body">
          <div class="detail-card">
            <div class="card-tit">基本信息</div>
            <div class="card-bot">
              <el-row class="view-list" :gutter="24">
                <!--            暂时这么处理距离问题-->
                <el-col
                  v-for="(item, index) in columns.base"
                  :key="item.prop"
                  :title="getValue(item)"
                  :span="8"
                  :style="{marginBottom: index !== 6 ? '20px' : 0, display: 'inline-flex' }"
                >
                  <div style="word-break: keep-all">{{ item.label }}：</div>
                  <div style="word-break: break-all">{{ getValue(item) }}</div>
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="detail-card">
            <div class="card-tit">工艺路线图</div>
            <div class="card-bot process-card">
              <ProcessFlow ref="processFlow" :read-only="true" :concise-process-flow="true" :process-flow-detail="processFlowVersion" />
            </div>
          </div>
        </div>
      </div>
      <!--      图纸及附件-->
      <div v-if="params.radio === 'drawAndFile' && params.materialsId" class="detail-card">
        <div class="card-tit">工序图纸及附件</div>
        <div class="card-bot">
          <div class="b20 flex-sbc">
            <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">图纸</span>
          </div>
          <br>
          <el-upload
            v-if="permission('UploadDrawing')"
            class="b20"
            type="primary"
            v-bind="uploadProps"
          >
            <el-button size="small" type="primary" @click="uploadKey = 'drawingsList'">上传图纸</el-button>
          </el-upload>
          <MTable ref="mTable" :show-page="false" :height="300" :columns="columns.drawings" :data="drawingsList">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a href="javascript:void(0)"
                 @click="openUrlGlobal(row.url)" :download="row.name" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
            <div slot="action" slot-scope="{ row }">
              <DelPopover v-if="permission('DelDrawing')" @onOk="(callback) => delFiles('drawingsList', row, callback)" />
            </div>
          </MTable>

          <div class="bt20 flex-sbc">
            <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px" @click="uploadKey = 'drawingsList'">其他附件</span>
          </div>
          <br>
          <el-upload
            v-if="permission('UploadFile')"
            class="b20"
            type="primary"
            v-bind="uploadProps"
          >
            <el-button size="small" type="primary" @click="uploadKey = 'filesList'">上传附件</el-button>
          </el-upload>
          <MTable ref="mTable" :show-page="false" :height="300" :columns="columns.files" :data="filesList">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a href="javascript:void(0)"
                 @click="openUrlGlobal(row.url)" :download="row.name" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
            <div slot="action" slot-scope="{ row }">
              <DelPopover v-if="permission('DelFile')" @onOk="(callback) => delFiles('filesList', row, callback)" />
            </div>
          </MTable>
        </div>
      </div>
      <!--      过程检/首检-->
      <div v-if="['inspectionProjectGroupProcess', 'inspectionProjectGroupFirst'].includes(params.radio)" class="inspection-wrapper" :style="{ top: params.materialsId ? '225px' : '203px' }">
        <inspection-setting v-if="params.materialsId" :current-key="params.radio" :materials-info="materialsInfo" />
        <MBlank v-else title="暂无内容" class="inspection-no-container" />
      </div>
      <!--      来料检/出货检-->
      <div v-if="['inspectionInComing', 'inspectionOutbound'].includes(params.radio)">
        <inspection-setting2 v-if="params.materialsId" :current-key="params.radio" :materials-id="materialsInfo.materialsId" />
      </div>
    </div>
    <materials-list v-show="['process', 'drawAndFile'].includes(params.radio)" :condition="materialsListCondition" style="position: absolute;top: 12px;right: 0;bottom: 37px" @cardClick="setMaterialId" />
    <SelectMaterials :visible.sync="materialVisible" :conditions="baseCondition" :single="true" @submitForm="setMaterialId" />
    <SelectProcessFlow
      :visible.sync="processFlowVisible"
      :select-process-and-version-ids="processIdAndVersionId"
      :is-add-process-flow="true"
      @submit="selectFlow"
    />
  </div>
</template>

<script>
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials'
import SearchForm from '@/components/SearchForm/SearchForm'
import { base } from '@/views/information/production/process/detail/columns'
import { searchFormList, searchFormLists } from './form-list'
import materialsApi from '@/api/information/materials'
import mApi from '@/api/information/materials-attribute'
import processApi from '@/api/information/production/process'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import SelectProcessFlow from '@/components/SelectProcessFlow/SelectProcessFlow'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import DelPopover from '@/components/DelPopover/index'
import { fileAndDrawings } from '@/components/ProcessFlow/columns'
import { getToken } from '@/utils/auth'
import columnsAll from './columns'
import FunctionHeader from '@/components/FunctionHeader/index'
import InspectionSetting from '@/views/information/materials-setting/components/inspection-setting'
import MaterialsList from '@/views/information/materials-setting/components/materials-list'
import InspectionSetting2 from '@/views/information/materials-setting/components/inspection-setting2'

export default {
  name: 'MaterialsSettingList',
  components: { InspectionSetting2, MaterialsList, InspectionSetting, ProcessFlow, SelectProcessFlow, SearchForm, SelectMaterials, DelPopover, FunctionHeader },
  data() {
    return {
      columnsAll,
      materialsApi,
      functionHeader: {
        exportName: 'exportMaterialsBom',
        importName: 'bom',
        searchVal: '1'
      },
      baseFormData: {},
      params: {
        materialsId: null,
        processId: null,
        radio: 'bom'
      },
      searchFormList,
      searchFormLists,
      formOptions: {
        materialsList: [],
        processList: []
      },
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      visible: false,
      materialVisible: false,
      processFlowVisible: false,
      processFlowDetail: false,
      processFlowVersion: false,
      columns: {
        base,
        drawings: fileAndDrawings,
        files: fileAndDrawings
      },
      processIdAndVersionId: [],
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        beforeUpload: this.beforeUpload
      },
      drawingsList: [],
      filesList: [],
      uploadKey: 'drawingsList',
      list: [],
      searchFormData: {
        requiredQuantity: '1',
        materialsId: ''
      },
      isFirst: false,
      searchData: {},
      lazy: false,
      expandAll: true,
      materialsList: [],
      materialsInfo: {},
      baseCondition: [{
        id: getUUid(),
        fieldName: 'isEnabled',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      }],
      materialsListCondition: [],
      memberCode:null
    }
  },
  computed: {
    height() {
      return this.params.materialsId
        ? this.$store.state.app.contentHeight - 257 - 77 + 48 - 20
        : this.$store.state.app.contentHeight - 257 - 77 + 48
    },
    heights() {
      return this.$store.state.app.contentHeight - 257 - 77 - 24 - 80
    }
  },
  mounted() {
    this.materialsList = this.$route.query.materialsList ? JSON.parse(this.$route.query.materialsList) : []
    this.memberCode=sessionStorage.getItem('memberCode')

    if (this.materialsList && this.materialsList.length > 0) {
      this.setMaterialId(this.materialsList)
      this.searchFormData.materialsId = this.materialsList[0].id
    }
    if (!this.permission('bomList')) {
      this.params.radio = 'process'
    }
    this.getProcessList()
  },
  activated(){
    this.materialsList = this.$route.query.materialsList ? JSON.parse(this.$route.query.materialsList) : []
    if (this.materialsList && this.materialsList.length > 0) {
      this.setMaterialId(this.materialsList)
      this.searchFormData.materialsId = this.materialsList[0].id
    }
    if (!this.permission('bomList')) {
      this.params.radio = 'process'
    }
    this.getProcessList()
  },
  methods: {
    radioChange(label) {
      if (['process'].includes(label)) {
        this.materialsListCondition = [{
          id: getUUid(),
          fieldName: 'materialsAttributeName',
          fieldType: 'string',
          fieldValue: '自制件^自制和采购件',
          operator: 'in'
        }, {
          id: getUUid(),
          fieldName: 'isEnabled',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }, {
          id: getUUid(),
          fieldName: 'processFlowId',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }]
      }
      if (['drawAndFile'].includes(label)) {
        this.materialsListCondition = [{
          id: getUUid(),
          fieldName: 'materialsAttributeName',
          fieldType: 'string',
          fieldValue: '自制件^自制和采购件',
          operator: 'in'
        }, {
          id: getUUid(),
          fieldName: 'isEnabled',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }, {
          id: getUUid(),
          fieldName: 'drawing',
          fieldType: 'string',
          fieldValue: '[]',
          operator: 'eq'
        }, {
          id: getUUid(),
          fieldName: 'attachment',
          fieldType: 'string',
          fieldValue: '[]',
          operator: 'eq'
        }]
      }
      if (['inspectionProjectGroupProcess', 'inspectionProjectGroupFirst'].includes(label)) {
        this.baseCondition = [{
          id: getUUid(),
          fieldName: 'isEnabled',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }, {
          id: getUUid(),
          fieldName: 'processFlowId',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'neq'
        }]
      } else {
        this.baseCondition = [{
          id: getUUid(),
          fieldName: 'isEnabled',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }]
      }
    },
    fixData(val) {
      const number = String(val).replace(/^(.*\..{4}).*$/, '$1')
      return Number(number)
    },
    // 遍历
    toggleRowExpansion_forAll(data, isExpansion) {
      data.forEach(item => {
        this.$refs.mTable.tableRefs().toggleRowExpansion(item, isExpansion)
        if (item.childBomList != undefined && item.childBomList != null) {
          this.toggleRowExpansion_forAll(item.childBomList, isExpansion)
        }
      })
    },
    getAll() {
      this.isFirst = !this.isFirst
      this.toggleRowExpansion_forAll(this.list, this.isFirst)
    },
    modifyOrCopy(row) {
      console.log('row:',row)
      this.$router.push({ name: 'editBom', query: { materialsList: JSON.stringify(this.formOptions.materialsList), detailData: JSON.stringify(row) }})
    },
    async getBom() {
      const data = this.$refs.searchForm.form.requiredQuantity
      this.searchData.requiredQuantity = data
      if (!this.params.materialsId) {
        this.$message.error('请选择物料')
        return false
      }
      if (!data) {
        this.$message.error('请输入需求数量')
        return false
      }
      const res = await mApi.getMaterialsBomList(this.params.materialsId, data)
      if (res) {
        await this.fixArrs(res)
        this.list = res
      }
    },
    fixArrs(res) {
      for (let i = 0; i < res.length; i++) {
        res[i].uuid = getUUid()
        if (res[i].childBomList && res[i].childBomList.length > 0) {
          this.fixArrs(res[i].childBomList)
        }
      }
    },
    async getProcessList() {
      const res = await processApi.getProcessList(this.defaultSearch)
      if (res) {
        this.formOptions.processList = res
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && row.prop !== 'areas') {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    currentMaterials(key) {
      return this.formOptions.materialsList.find(item => item.id === this.params.materialsId)[key]
    },
    async materialsChange(id, list) {
      if (id) {
        let copyList = this._.clone(list)
        if (!copyList) {
          this.formOptions.materialsList = await materialsApi.getMaterialsList({
            searchVal: Encrypt(JSON.stringify(
              [{
                id: getUUid(),
                fieldName: 'id',
                fieldType: 'number',
                fieldValue: this.formOptions.materialsList.map(item => item.id).join('^'),
                operator: 'in'
              }]
            ))
          })
          copyList = this.formOptions.materialsList.filter(item => item.id === this.params.materialsId)
        }
        this.formOptions.materialsList = copyList
        this.getProcessAndFiles(copyList)
        if (!['reagold'].includes(sessionStorage.getItem('memberCode'))) {
          this.getBom()
        }
      } else {
        this.baseFormData = {}
        this.processFlowVersion = null
        this.processIdAndVersionId = []
      }
    },
    async getProcessAndFiles(copyList) {
      const current = copyList[0]
      if (current) {
        this.materialsInfo = {
          materialsId: current.id,
          processId: current.processFlowId,
          versionId: current.processFlowVersionId
        }
        this.drawingsList = current.drawing ? JSON.parse(current.drawing) : []
        this.filesList = current.attachment ? JSON.parse(current.attachment) : []
      }

      if (current && !current.processFlowId) {
        this.processFlowVersion = false
        this.processIdAndVersionId = []
        return true
      }
      const res = await processApi.getProcessDetail({
        id: current.processFlowId,
        versionId: current.processFlowVersionId
      })
      if (res) {
        this.baseFormData = res
        this.processFlowVersion = res.processFlowVersion
        this.processIdAndVersionId = [res.id, res.processFlowVersion.id]
      }
    },
    // 删除工艺
    async delProcess() {
      this.$confirm('您确定要解绑吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await materialsApi.modifyMaterials({
          ...this.createResetData(),
          processFlowId: 0,
          processFlowVersionId: 0
        })
        if (res) {
          await this.materialsChange(this.params.materialsId)
          this.$message.success('删除成功')
        }
      })
    },
    // 上传图纸/附件
    async fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        const current = await materialsApi.getMaterialsDetailList(this.params.materialsId)
        if (current) {
          const data = {
            drawingsList: current.drawing ? JSON.parse(current.drawing) : [],
            filesList: current.attachment ? JSON.parse(current.attachment) : []
          }
          data[this.uploadKey].push({
            id: file.uid,
            name: file.name,
            url: res.result
          })
          const materials = await materialsApi.modifyMaterials({
            ...this.createResetData(),
            drawing: JSON.stringify(data.drawingsList || []),
            attachment: JSON.stringify(data.filesList || [])
          })
          if (materials) {
            const currentIndex = this.formOptions.materialsList.findIndex(item => item.id === this.params.materialsId)
            Object.assign(this.formOptions.materialsList[currentIndex], {
              processFlowId: 0,
              processFlowVersionId: 0
            })
            await this.materialsChange(this.params.materialsId)
            this.$message.success('上传成功')
          }
        }
      } else {
        this.$message.error(res.result)
      }
    },
    // 删除图纸及附件
    async delFiles(key, row, callback) {
      const current = await materialsApi.getMaterialsDetailList(this.params.materialsId)
      if (current) {
        const data = {
          drawingsList: current.drawing ? JSON.parse(current.drawing) : [],
          filesList: current.attachment ? JSON.parse(current.attachment) : []
        }
        const currentIndex = data[key].findIndex(item => item.id === row.id)
        data[key].splice(currentIndex, 1)
        const res = await materialsApi.modifyMaterials({
          ...this.createResetData(),
          drawing: JSON.stringify(data.drawingsList || []),
          attachment: JSON.stringify(data.filesList || [])
        })
        if (res) {
          await this.materialsChange(this.params.materialsId)
          this.$message.success('删除成功')
          callback()
        }
      }
    },
    selectMaterials() {
      this.materialVisible = true
    },
    selectProcessFlow() {
      this.processFlowVisible = true
    },
    // 选择物料
    setMaterialId(list) {
      this.params.materialsId = list[0].id
      this.formOptions.materialsList = list
      this.materialVisible = false
      this.searchData = {
        materialsId: list[0].id,
        requiredQuantity: 1
      }
      if (!['reagold'].includes(sessionStorage.getItem('memberCode'))) {
        this.getBom()
      }
    },
    // 选择工艺
    async selectFlow(data) {
      const res = await materialsApi.modifyMaterials({
        ...this.createResetData(data),
        processFlowId: data.processData.id,
        processFlowVersionId: data.processFlowVersion.id
      })
      if (res) {
        await this.materialsChange(this.params.materialsId)
        this.processFlowVisible = false
        this.$message.success('设置成功')
      }
    },
    createResetData() {
      const raw = this.formOptions.materialsList.find(item => item.id === this.params.materialsId)
      return {
        id: raw.id,
        name: raw.name,
        code: raw.code,
        specifications: raw.specifications,
        mainUnitId: raw.mainUnitId,
        mainUnitName: raw.mainUnitName,
        materialsClassId: raw.materialsClassId,
        materialsClassName: raw.materialsClassName,
        materialsTypeId: raw.materialsTypeId,
        materialsTypeName: raw.materialsTypeName
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.material-font {
  display: inline-flex;
  padding-left: 20px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #393D60;
  line-height: 20px;
}
.all-material {
  display: flex;
  justify-content: flex-start;
  .ri-search {
    display: flex;
    justify-content: flex-start;
  }
}
.function-header-wrap {
  padding-top: 0;
}
::v-deep .top .el-form-item__content {
  margin-left: 0 !important;
}
.m-radio-group {
  margin: 10px 10px 0 10px;
}

.materials-setting-wrap {
  width: 100%;
  background: #FFFFFF;

  .top {
    border-bottom: 1px solid #DDE3EB;
    padding-bottom: 10px;

    ::v-deep {
      .search-form-wrap {
        border: none;

        form {
          .el-row {
            .el-col:last-child {
              .el-form-item__content {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    padding: 10px;
    overflow-y: auto;
  }
}

.process-info {
  display: flex;
  align-items: center;

  .el-tag {
    border-color: #DDE3EB;
    background: #F6F7F9;
    color: #9597AE;
    margin: 0 20px;
  }

  button {
    img {
      width: 16px;
      position: relative;
      top: 1px;
    }
  }
}

::v-deep input[classname=select-materials] {
  display: none;
}

::v-deep .el-input-group--prepend {
  width: 100%;
  height: 32px;
}

::v-deep .el-input-group__prepend {
  background: #FFFFFF;
  width: calc(100% - 56px);

  > div {
    width: calc(100% + 32px);
  }

  .input-prepend {
    width: 100%;
    color: #495060;
  }
}

::v-deep .el-input-group__append {
  width: 56px;
}
.inspection-wrapper {
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 36px;
  background: #F0F2F5;
}
.inspection-no-container{
  width: 100%;
  height: calc(100% - 12px);
}
</style>
