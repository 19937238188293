<template>
  <div class="procedure-detail-wrapper">
    <div class="procedure-detail-header flex-sbc">
      <div class="left" @click="$router.push({ name: 'procedureList' })">
        <img
          src="@/assets/information/procedure/左滑@2x.png"
          style="width: 8px"
        />
        <span style="padding-left: 10px; cursor: pointer">{{
          messages[params.type + "O"]
        }}</span>
      </div>
      <div class="right">
        <el-button
          v-if="permission('Modify')"
          type="primary"
          :loading="buttonLoading"
          @click="switchOrSubmit"
        >
          {{ saveButton ? "保存" : "编辑" }}
        </el-button>
        <el-button
          v-if="permission('Synchronous') && !saveButton"
          type="primary"
          :loading="buttonLoading"
          @click="synchronousToProcess"
        >
          同步至工艺流程
        </el-button>
      </div>
    </div>

    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder
            v-if="saveButton"
            ref="baseForm"
            :form-list="baseFormList"
            :form-data="baseFormData"
          >
            <div slot="issueMode" class="flex-mode" v-if="memberType === 'line'">
              <div class="tit">默认下发方式：</div>
              <el-radio-group
                v-model="selectParams.issueMode"
                name="issueGroup"
                @change="assingChanges(1)"
              >
                <el-radio
                  v-for="items in issList"
                  :key="items.value"
                  :label="items.value"
                >{{ items.name }}</el-radio
                >
              </el-radio-group>
            </div>
            <div slot="assignMode" class="flex-mode" v-if="memberType === 'line'">
              <div class="tit">默认指派方式：</div>
              <el-radio-group
                v-model="selectParams.assignMode"
                name="assingGroup"
                @change="assingChanges(2)"
              >
                <el-radio
                  v-for="items in assList"
                  :key="items.value"
                  :label="items.value"
                >{{ items.name }}</el-radio
                >
              </el-radio-group>
            </div>
            <div slot="reportSettig" class="flex-modes" v-if="memberType === 'line'">
              <div class="tit">
                <el-tooltip class="item" effect="dark" content="工单仅一个工序时公差控制报工数量生效，多于一个工序时不生效" placement="top">
                  <span class="el-icon-question" />
                </el-tooltip>
                工序报工公差设置：
              </div>
              <div class="mode-ri">
                <el-select v-model="differenceType" placeholder="请选择" class="ri-select">
                  <el-option
                    v-for="item in procedureDifferenceTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <div class="ri-top">
                  <div class="top-tit">上公差：</div>
                  <el-input-number v-model="differencePlus" :min="0" controls-position="right" :step="1" class="ri-num" :disabled="differenceType || differenceType === 0 ? false : true" />
                </div>
                <div class="ri-top">
                  <div class="top-tit">下公差：</div>
                  <el-input-number v-model="differenceMinus" :min="0" :max="maxData" controls-position="right" :step="1" class="ri-num" :disabled="differenceType || differenceType === 0 ? false : true" />
                </div>
              </div>
            </div>
            <div slot="groupName">
              <CURDSelect
                :default-value="baseFormData.groupId"
                api-name="ProcedureGroup"
                @change="selectName"
              />
            </div>
          </MFormBuilder>
          <el-row v-else class="view-list" :gutter="24">
            <!--            暂时这么处理距离问题-->
            <el-col
              v-for="(item, index) in fixData(columns.bases)"
              :key="item.prop"
              :span="6"
              :style="{ marginBottom: index !== 4 ? '20px' : 0 }"
            >
              <span class="bot-tis">{{ item.label }}：</span>
              <span class="bot-tis">{{ getValue(item) }}</span>
            </el-col>
            <el-col :span="24" :style="{ margin: '20px 0' }" v-if="memberType === 'line'">
              <div class="flex-mode">
                <div class="tit">默认下发方式：</div>
                <el-radio-group
                  v-model="selectParams.issueMode"
                  name="assingGroups"
                  disabled
                >
                  <el-radio
                    v-for="items in issList"
                    :key="items.value"
                    :label="items.value"
                  >{{ items.name }}</el-radio
                  >
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="24" v-if="memberType === 'line'">
              <div class="flex-mode">
                <div class="tit">默认指派方式：</div>
                <el-radio-group
                  v-model="selectParams.assignMode"
                  name="assingGroup"
                  disabled
                >
                  <el-radio
                    v-for="items in assList"
                    :key="items.value"
                    :label="items.value"
                  >{{ items.name }}</el-radio
                  >
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="24" v-if="memberType === 'line'">
              <div class="flex-modes">
                <div class="tit">
                  <el-tooltip class="item" effect="dark" content="工单仅一个工序时公差控制报工数量生效，多于一个工序时不生效" placement="top">
                    <span class="el-icon-question" />
                  </el-tooltip>
                  工序报工公差设置：
                </div>
                <div class="mode-ri">
                  <el-select v-model="differenceType" size="small" placeholder="请选择" class="ri-select" disabled>
                    <el-option
                      v-for="item in procedureDifferenceTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <div class="ri-top">
                    <div class="top-tit">上公差：</div>
                    <div class="top-tit">{{ differencePlus }}</div>
                  </div>
                  <div class="ri-top">
                    <div class="top-tit">下公差：</div>
                    <div class="top-tit">{{ differenceMinus }}</div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="detail-card" v-if="!['mom'].includes(memberCode)">
        <div class="card-tit">可执行工位</div>
        <div class="card-bot">
          <el-button
            v-if="saveButton"
            class="b20"
            type="primary"
            @click="addStation"
          >添加工位</el-button
          >
          <MTable
            ref="mTable"
            :show-page="false"
            :height="300"
            :columns="columns.station"
            :data="stationList"
          >
            <div slot="index" slot-scope="{ row, $index }">
              {{ $index + 1 }}
            </div>
            <div slot="action" slot-scope="{ $index }">
              <el-button
                type="text"
                :disabled="!saveButton"
                @click="delFrontData('stationList', $index)"
              >删除</el-button
              >
            </div>
          </MTable>
        </div>
      </div>
      <div class="detail-card" v-if="memberType === 'line'">
        <div class="card-tit">工序指导字段配置</div>
        <div class="card-bot">
          <div class="pcCard">
            <div class="typeTitle">PC端</div>

            <div class="card-bot-item">
              <div class="item-title">生产订单</div>
              <div style="display: flex">
                <div v-for="(item, index) in procedureFieldListPc" :key="index">
                  <div v-if="item.type === 1" class="fieldItem">
                    <el-checkbox
                      :disabled="!saveButton"
                      v-model="item.isShow"
                    >{{ item.fieldName }}</el-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card-bot-item">
              <div class="item-title">生产计划</div>
              <div style="display: flex">
                <div v-for="(item, index) in procedureFieldListPc" :key="index">
                  <div v-if="item.type === 2" class="fieldItem">
                    <el-checkbox
                      :disabled="!saveButton"
                      v-model="item.isShow"
                    >{{ item.fieldName }}</el-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card-bot-item">
              <div class="item-title">生产工单</div>
              <div style="display: flex">
                <div v-for="(item, index) in procedureFieldListPc" :key="index">
                  <div v-if="item.type === 3" class="fieldItem">
                    <el-checkbox
                      :disabled="!saveButton"
                      v-model="item.isShow"
                    >{{ item.fieldName }}</el-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card-bot-item">
              <div class="item-title">自制任务</div>
              <div style="display: flex">
                <div v-for="(item, index) in procedureFieldListPc" :key="index">
                  <div v-if="item.type === 4" class="fieldItem">
                    <el-checkbox
                      :disabled="!saveButton"
                      v-model="item.isShow"
                    >{{ item.fieldName }}</el-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pcCard">
            <div class="typeTitle">移动端</div>
            <div class="card-bot-item">
              <div class="item-title">生产订单</div>
              <div style="display: flex">
                <div v-for="(item, index) in procedureFieldListApp" :key="index">
                  <div v-if="item.type === 1" class="fieldItem">
                    <el-checkbox :disabled="!saveButton" v-model="item.isShow">{{
                      item.fieldName
                      }}</el-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-bot-item">
              <div class="item-title">生产计划</div>
              <div style="display: flex">
                <div v-for="(item, index) in procedureFieldListApp" :key="index">
                  <div v-if="item.type === 2" class="fieldItem">
                    <el-checkbox :disabled="!saveButton" v-model="item.isShow">{{
                      item.fieldName
                      }}</el-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-bot-item">
              <div class="item-title">生产工单</div>
              <div style="display: flex">
                <div v-for="(item, index) in procedureFieldListApp" :key="index">
                  <div v-if="item.type === 3" class="fieldItem">
                    <el-checkbox :disabled="!saveButton" v-model="item.isShow">{{
                      item.fieldName
                      }}</el-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-bot-item">
              <div class="item-title">自制任务</div>
              <div style="display: flex">
                <div v-for="(item, index) in procedureFieldListApp" :key="index">
                  <div v-if="item.type === 4" class="fieldItem">
                    <el-checkbox :disabled="!saveButton" v-model="item.isShow">{{
                      item.fieldName
                      }}</el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pcCard">
            <div class="card-bot-item">
              <div class="item-title">
                <span>自定义</span>
                <el-button
                  v-if="saveButton"
                  type="text"
                  size="small"
                  style="margin-left: 10px"
                  @click="addField"
                >新增字段</el-button
                >
              </div>
              <div style="display: flex; align-items: center">
                <div
                  v-for="(item, index) in procedureFieldListCustom"
                  :key="index"
                >
                  <div v-if="item.type === 5" class="fieldItem">
                    <div style="display: flex; align-items: center">
                      <div>
                        <el-checkbox
                          :disabled="!saveButton"
                          v-model="item.isShow"
                        >{{ item.fieldName }}</el-checkbox
                        >
                      </div>

                      <el-button
                        v-if="saveButton"
                        type="text"
                        size="small"
                        style="margin-left: 10px"
                        @click="deleteField(item, index)"
                      >删除</el-button
                      >
                      <el-divider
                        v-if="saveButton"
                        direction="vertical"
                      ></el-divider>
                      <el-button
                        v-if="saveButton"
                        type="text"
                        size="small"
                        @click="addField(item, index)"
                        style="margin-right: 20px"
                      >修改</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">工序操作描述</div>
        <div class="card-bot">
          <el-input
            v-model="operationDesc"
            type="textarea"
            placeholder="请输入工序操作描述"
            maxlength="500"
            :disabled="!saveButton"
            show-word-limit
          />
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">工序图纸及附件</div>
        <div class="card-bot">
          <div class="b20 flex-sbc">
            <img
              src="@/assets/information/procedure/图纸@2x.png"
              style="width: 18px"
            />
            <span class="l10" style="position: relative; top: 2px">图纸</span>
          </div>
          <br />
          <el-upload
            v-if="saveButton"
            class="b20"
            type="primary"
            v-bind="uploadProps"
          >
            <el-button
              size="small"
              type="primary"
              @click="uploadKey = 'drawingsList'"
            >上传图纸</el-button
            >
          </el-upload>
          <MTable
            ref="mTable"
            :show-page="false"
            :height="300"
            :columns="columns.drawings"
            :data="drawingsList"
          >
            <div slot="index" slot-scope="{ row, $index }">
              {{ $index + 1 }}
            </div>
            <div slot="fileName" slot-scope="{ row }">
              <a
                :href="row.url"
                :download="row.name"
                target="_blank"
                style="color: #607fff"
              >{{ row.name }}</a
              >
              <img
                src="@/assets/information/procedure/附件@2x.png"
                style="width: 11px; margin-left: 5px"
              />
            </div>
            <div slot="action" slot-scope="{ $index }">
              <el-button
                type="text"
                :disabled="!saveButton"
                @click="delFrontData('drawingsList', $index)"
              >删除</el-button
              >
            </div>
          </MTable>

          <div class="bt20 flex-sbc">
            <img
              src="@/assets/information/procedure/其他附件@2x.png"
              style="width: 18px"
            />
            <span
              class="l10"
              style="position: relative; top: 2px"
              @click="uploadKey = 'filesList'"
            >其他附件</span
            >
          </div>
          <br />
          <el-upload
            v-if="saveButton"
            class="b20"
            type="primary"
            v-bind="uploadProps"
          >
            <el-button
              size="small"
              type="primary"
              @click="uploadKey = 'filesList'"
            >上传附件</el-button
            >
          </el-upload>
          <MTable
            ref="mTable"
            :show-page="false"
            :height="300"
            :columns="columns.files"
            :data="filesList"
          >
            <div slot="index" slot-scope="{ row, $index }">
              {{ $index + 1 }}
            </div>
            <div slot="fileName" slot-scope="{ row }">
              <a
                :href="row.url"
                :download="row.name"
                target="_blank"
                style="color: #607fff"
              >{{ row.name }}</a
              >
              <img
                src="@/assets/information/procedure/附件@2x.png"
                style="width: 11px; margin-left: 5px"
              />
            </div>
            <div slot="action" slot-scope="{ $index }">
              <el-button
                type="text"
                :disabled="!saveButton"
                @click="delFrontData('filesList', $index)"
              >删除</el-button
              >
            </div>
          </MTable>
        </div>
      </div>

      <div class="detail-card" v-if="memberType === 'line'">
        <div class="card-tit">工序上传参数</div>
        <div class="card-bot">
          <ProcedureParams
            ref="procedureParams"
            :read-only="!saveButton"
            :group-list="groupList"
          />
        </div>
      </div>

      <template v-if="!['bbelc'].includes(memberCode)">
        <div v-if="procedureType === 1" class="detail-card">
          <div class="card-tit">外协工序时间设置</div>
          <div class="card-bot">
            <span style="padding-left: 10px">外协默认周期：</span>
            <el-input-number
              v-model="outsourceTime"
              size="small"
              controls-position="right"
              :min="0"
              :step="1"
              :disabled="!saveButton"
              style="width: 200px"
            />
            <span style="padding-left: 10px">天</span>
          </div>
        </div>

        <div
          v-if="!['bbelc', 'jinshi'].includes(memberCode)"
          class="detail-card"
        >
          <div class="card-tit">工序质检设置</div>
          <div class="card-bot">
            <el-tabs v-model="activeName" @tab-click="tabChange">
              <template v-for="(item, index) in tabs">
                <el-tab-pane
                  v-if="
                    procedureType !== 1 ||
                    (procedureType === 1 &&
                      item.key !== 'inspectionProjectGroupFirst')
                  "
                  :key="index"
                  :label="item.label"
                  :name="item.key"
                >
                  <div>
                    <span style="padding-left: 10px">是否需要质检：</span>
                    <el-radio-group
                      v-model="qualityInspectionPlan[item.key].isNeedInspect"
                      :name="item.key"
                      :disabled="!saveButton"
                      @change="
                        (val) => {
                          isNeedQualityInspectionChange(val, item.key);
                        }
                      "
                    >
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                  <div style="margin-top: 10px" v-if="memberType === 'line'">
                    <span style="padding-left: 10px">质检完成才能出站：</span>
                    <el-radio-group
                      v-model="qualityInspectionPlan[item.key].isMustCheck"
                      :name="item.key"
                      :disabled="!saveButton"
                    >
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                  <div
                    v-if="qualityInspectionPlan[item.key].isNeedInspect === 1"
                    style="padding-top: 15px"
                  >
                    <span style="padding-left: 10px">检验类型：</span>
                    <el-select
                      v-model="qualityInspectionPlan[item.key].isFullInspection"
                      :name="item.key"
                      :disabled="!saveButton"
                    >
                      <el-option :label="'全检'" :value="1">全检</el-option>
                      <el-option :label="'抽检'" :value="0">抽检</el-option>
                    </el-select>
                  </div>
                  <div
                    v-if="qualityInspectionPlan[item.key].isNeedInspect === 1"
                    style="padding: 15px 0"
                  >
                    <span style="padding-left: 10px">质检方案：</span>
                    <el-select
                      v-model="qualityInspectionPlan[item.key].inspectId"
                      size="small"
                      :disabled="!saveButton"
                      filterable
                      style="width: 200px"
                      placeholder="请选择质检方案"
                      @change="(val) => qualityInspectionChange(val, item.key)"
                    >
                      <el-option
                        v-for="is in formOptions.inspectionPlanList"
                        :key="is.id"
                        :label="is.name"
                        :value="is.id"
                      />
                    </el-select>
                  </div>
<!--                  报检信息展示————————Start————————-->
                  <div style="margin: 15px;display: none">
                    <el-checkbox disabled v-model="baojianMsg.topChecked">使用报检信息</el-checkbox>
                    <el-select v-if="memberCode === 'fsd' || memberCode === 'mom' || memberCode === 'ydhb'" disabled style="margin-left: 30px;margin-right: 30px"
                               v-model="baojianMsg.topSelected" placeholder="请选择报检信息">
                      <el-option label="焊缝" value="焊缝"></el-option>
                      <el-option label="筒节" value="筒节"></el-option>
                    </el-select>
                    <div class="baojian-container" v-if="baojianMsg.bjList.length > 0">
                      <div style="min-width: 90px">
                        <div class="divItem">项目</div>
                        <div class="divItem divItemS">参数类型</div>
                        <div class="divItem divItemS">可能值</div>
                        <div class="divItem divItemS">默认值</div>
                        <div class="divItem divItemS">是否必填</div>
                      </div>
                      <div class="content1" v-for="(bjItem,bjIndex) in baojianMsg.bjList">
                        <div class="divItem" style="position:relative;">
                          {{ bjItem.inspectionEntryName }}
                          <div class="edd" style="display: none">
                            <el-button :disabled="!saveButton" icon="el-icon-edit" type="text" style="padding: 0;font-size: 16px;"
                                       @click="modifyInspectionItem(bjItem,bjIndex)" />
                            <el-button :disabled="!saveButton" icon="el-icon-remove-outline" type="text" style="padding: 0;font-size: 16px;margin-left: 4px"
                                       @click="deleteInspectionItem(bjIndex)" />
                          </div>
                        </div>
                        <div class="divItem divItemSW">{{bjItem.parameterType | parmeTypeName}}</div>
                        <div class="divItem divItemSW">{{bjItem.possibleValue.length ? bjItem.possibleValue.join(",") : '-'}}</div>
                        <div class="divItem divItemSW" v-if="bjItem.defaultIsDynamic">{{bjItem.defaultDynamicEntry}} | {{bjItem.defaultDynamicItem}}</div>
                        <div class="divItem divItemSW" v-else>{{ Array.isArray(bjItem.defaultValue) ? (bjItem.defaultValue.join(",") ? bjItem.defaultValue.join(",") : '-')
                          : bjItem.defaultValue ? bjItem.defaultValue : '-'}}</div>
                        <div class="divItem divItemSW">{{bjItem.required ? '必填' : '非必填'}}</div>
                      </div>
                    </div>
                  </div>
<!--                  报检信息展示————————End————————-->
                  <quality-inspection-project
                    v-if="
                      qualityInspectionPlan[item.key].isNeedInspect === 1 &&
                      qualityInspectionPlan[item.key].inspectId
                    "
                    :ref="item.ref"
                    :inspection-entry-map="
                      qualityInspectionPlan[item.key].inspectEntryMap
                    "
                    :attachments="
                      qualityInspectionPlan[item.key].inspectAttachments
                    "
                    :user-list="qualityInspectionPlan[item.key].inspectorIdList"
                    :read-only="!saveButton"
                    :baseFormData="qualityInspectionPlan[item.key]"
                  />
                </el-tab-pane>
              </template>
            </el-tabs>
          </div>
        </div>
      </template>
    </div>
    <MDialog
      v-model="fieldVisible"
      width="400px"
      :title="isAddField ? '新增自定义字段' : '编辑自定义字段'"
      @onOk="submitFieldForm"
    >
      <el-input
        v-model="addOrModifyField.fieldName"
        placeholder="请输入自定义字段"
        style="padding-right: 15px"
      />
    </MDialog>
    <MDialog v-model="visible" title="添加工位" @onOk="submitStationForm">
      <MFormBuilder
        ref="formBuild"
        :form-data="formData"
        :form-list="formList"
      />
    </MDialog>
    <MDialog
      v-model="processVisible"
      title="选择同步模式"
      @onOk="submitProcessForm"
    >
      <el-radio-group
        v-model="tongRadio"
        style="display: block; margin-bottom: 10px"
      >
        <el-radio
          :label="1"
          value="1"
          style="display: block; margin-bottom: 10px"
        >同步至所有相关工艺路线</el-radio
        >
        <el-radio :label="2" value="2">同步到指定工艺路线</el-radio>
      </el-radio-group>
      <el-cascader-multi
        v-model="onlyProcess"
        placeholder="工艺-版本号"
        :value-key="'id'"
        :label-key="'version'"
        :children-key="'versionList'"
        :data="onlyProcessList"
        :only-last="true"
        :disabled="tongRadio === 1"
        style="margin-right: 20px; margin-left: 25px; width: 80%"
      />
      <!-- <el-cascader
        ref="myCascader"
        @change="shareScopeChange"
        v-model="onlyProcess"
        clearable
        size="small"
        placeholder="版本号"
        :show-all-levels="false"
        :options="onlyProcessList"
        style="margin-right: 20px;width: 250px;margin-left: 25px;"
        :props="{  multiple: true, label: 'version', value: 'id', children: 'versionList', disabled: 'disabled' }"
        :disabled="tongRadio === 1"
      /> -->
    </MDialog>
  </div>
</template>

<script>
  import { base, station, fileAndDrawings, getFunctionList } from "./columns";
  import { baseFormList } from "./form-list";
  import ProcedureParams from "@/components/ProcedureParams/ProcedureParams";
  import stationApi from "@/api/information/production/station";
  import QIP from "@/api/quality/inspection-plan";
  import { Encrypt } from "@/utils/sercet";
  import { getUUid, setTreeData } from "@/utils";
  import { status } from "@/config/options.config";
  import procedureApi from "@/api/information/production/procedure";
  import baseSettingApi from "@/api/sets/base-setting";
  import CURDSelect from "@/components/CURDSelect/CURDSelect";
  import { getToken } from "@/utils/auth";
  import QualityInspectionProject from "@/views/quality/inspection-plan/components/quality-inspection-project";
  import api from "@/api/information/production/procedure";
  import wapi from '@/api/information/production/work-center'

  export default {
    // eslint-disable-next-line vue/name-property-casing
    name: "procedureDetail",
    components: { QualityInspectionProject, CURDSelect, ProcedureParams },
    data() {
      return {
        baojianMsg: {
          visiableBJ:false,
          currentTypeBJ:"新增报检项",
          topChecked: false,
          topSelected: '',
          dataForAddOrModify:{
            name:'',
            type:1,
            required:0,
            defaultValue:'',
            possibleValue:[]
          },
          bjList:[]
        },
        procedureFieldListPc: [
          {
            id: null,
            fieldCode: "customerName",
            fieldName: "客户",
            type: 1,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "materialsInfo",
            fieldName: "成品",
            type: 1,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "batchNumber",
            fieldName: "批次码",
            type: 2,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "deliveryTime",
            fieldName: "交期",
            type: 2,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "produceWorkOrderNumber",
            fieldName: "工单号",
            type: 3,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "leftNumber",
            fieldName: "成品相关数量",
            type: 4,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "rightNumber",
            fieldName: "中间品相关数量",
            type: 4,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "remark",
            fieldName: "备注",
            type: 4,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "midMaterial",
            fieldName: "中间品",
            type: 4,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "procedureName",
            fieldName: "工序名",
            type: 4,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "sourceQuantity",
            fieldName: "上道工序产出数量",
            type: 4,
            isShow: true,
          },

          // {
          //   id: null,
          //   fieldCode: "mainUnitName",
          //   fieldName: "单位",
          //   type: 4,
          //   isShow: true,
          // },
        ],
        procedureFieldListApp: [
          {
            id: null,
            fieldCode: "materialsInfo",
            fieldName: "成品",
            type: 1,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "materialsNumber",
            fieldName: "成品相关数量",
            type: 4,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "batchNumber",
            fieldName: "批次码",
            type: 2,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "produceWorkOrderNumber",
            fieldName: "工单号",
            type: 3,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "midMaterial",
            fieldName: "中间品",
            type: 4,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "midMaterialNumber",
            fieldName: "中间品相关数量",
            type: 4,
            isShow: true,
          },

          {
            id: null,
            fieldCode: "procedureName",
            fieldName: "工序名",
            type: 4,
            isShow: true,
          },
          {
            id: null,
            fieldCode: "procedureNameNow",
            fieldName: "当前所在工序",
            type: 4,
            isShow: true,
          },
        ],
        procedureFieldListCustom: [],
        addOrModifyField: {
          fieldName: "",
        },
        isAddField: true,
        fieldIndex: 0,
        selectedData: [],
        value: [],
        shareScopeEnd: [],
        onlyProcess: [],
        onlyProcessList: [],
        tongRadio: 1,
        processVisible: false,
        activeName: "inspectionProjectGroupProcess",
        tabs: [
          {
            label: "过程检",
            ref: "qualityInspectionProjectProcess",
            key: "inspectionProjectGroupProcess",
          },
          // {
          //   label: '首检',
          //   ref: 'qualityInspectionProjectFirst',
          //   key: 'inspectionProjectGroupFirst'
          // }
        ],
        isFirst: false,
        maxData: undefined,
        differenceType: undefined,
        differenceMinus: undefined,
        differencePlus: undefined,
        selectParams: {
          issueMode: 0,
          assignMode: 0,
        },
        procedureDifferenceTypes: [
          {
            id: 0,
            name: "数值",
          },
          {
            id: 1,
            name: "百分比",
          },
        ],
        issList: [
          {
            value: 0,
            name: "工序任务无需下发，拥有权限的工位均可操作",
          },
          {
            value: 1,
            name: "工序任务必须下发至拥有权限的工位才可进行操作",
          },
        ],
        assList: [
          {
            value: 0,
            name: "工序任务无需指派，拥有权限人员均可操作",
          },
          {
            value: 1,
            name: "工序任务必须指派至拥有权限的用户/用户组才可进行操作",
          },
        ],
        params: {},
        baseFormList,
        baseFormData: {},
        saveButton: false,
        buttonLoading: false,
        columns: {
          bases: base,
          station,
          drawings: fileAndDrawings,
          files: fileAndDrawings,
        },
        uploadProps: {
          action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
          headers: { token: getToken() },
          showFileList: false,
          onSuccess: this.fileUploadChange,
          onError: this.fileUploadChange,
          beforeUpload: this.beforeUpload,
        },
        operationDesc: "",
        qualityInspectionPlan: {
          inspectionProjectGroupFirst: {
            isNeedInspect: 0,
            isFullInspection: 1,
            isMustCheck: 0,
          },
          inspectionProjectGroupProcess: {
            isNeedInspect: 0,
            isFullInspection: 1,
            isMustCheck: 0,
          },
        },
        stationList: [],
        drawingsList: [],
        filesList: [],
        uploadKey: "drawingsList",
        formOptions: {
          stationOptions: [],
          inspectionPlanList: [],
        },
        visible: false,
        fieldVisible: false,
        formData: {},
        formList: [
          {
            key: "stationIds",
            tagName: "el-select",
            colSpan: 24,
            props: {
              placeholder: "请选择工位",
              multiple: true,
            },
            children: [
              {
                tagName: "el-option",
                props: {
                  label: "name",
                  value: "id",
                },
                options: status,
              },
            ],
          },
        ],
        defaultSearch: {
          searchVal: Encrypt(
            JSON.stringify([
              {
                id: getUUid(),
                fieldName: "isEnabled",
                fieldType: "number",
                fieldValue: 1,
                operator: "eq",
              },
            ])
          ),
        },
        groupList: [],
        messages: {
          add: "新增成功",
          modify: "修改成功",
          copy: "复制成功",
          viewO: "工序详情",
          addO: "新增工序",
          modifyO: "修改工序",
          copyO: "复制工序",
        },
        inputVisible: false,
        procedureGroups: "common",
        procedureGroupList: [],
        currentIdx: "",
        inputValue: "",
        procedureGroupId: "",
        procedureGroupName: "",
        typeTitle: new Map([
          ["add", "新增类型"],
          ["modify", "编辑类型"],
          ["del", "删除"],
        ]),
        outsourceTime: null,
        procedureType: 0,
        procedureClassList: [],
      };
    },
    computed: {
      memberCode() {
        return sessionStorage.getItem("memberCode");
      },
      memberType(){
        return localStorage.getItem('memberType')
      }
    },
    watch: {
      differenceType(val) {
        if (!this.isFirst) {
          this.isFirst = true;
          return false;
        }
        if (val === 1) {
          this.maxData = 100;
        } else {
          this.maxData = undefined;
        }
        this.differenceMinus = undefined;
        this.differencePlus = undefined;
      },
    },
    beforeMount() {
      getFunctionList({
        procedureTypeChange: this.procedureTypeChange,
        procedureClassChange: this.procedureClassChange,
      });
    },
    mounted() {
      this.params = this.$route.query;
      this.saveButton = this.params.type !== "view";
      this.getStationList();
      this.getProcedureClassList();
      // this.getWorkCenterList();
      if (!["bbelc", "jinshi"].includes(this.memberCode)) {
        this.getQualityInspectionPlanList();
      }
      if (this.params.type !== "add") {
        this.getProcedureDetailById(this.params.id);
        this.getProcedureIdProcess();
      }
      if (this.params.type === "add") {
        this.getIssueConfig();
        this.createProcedureCode();
      }
    },
    methods: {
      // 获取工序分类
      async getProcedureClassList() {
        const res = await api.getProcedureClassList(this.defaultSearch);
        if (res) {
          this.procedureClassList = res;
          this.baseFormList[2].props.options = setTreeData(res);
          this.columns.bases[2].form.options = res;
        }
      },
      // 获取工作中心
      async getWorkCenterList() {
        const res = await wapi.getWorkCenterList()
        if (res) {
          console.log(this.baseFormList[6])
          this.baseFormList[6].children[0].options = res
        }
      },
      procedureClassChange(val) {
        const name = this.procedureClassList.filter(
          (item) => item.id === +this._.last(val)
        )[0].name;
        this.$refs.baseForm.setForm({
          procedureClassName: name,
          procedureClassId: this._.last(val),
        });
      },
      async createProcedureCode() {
        if (sessionStorage.getItem("memberCode") !== "mubai") return false;
        const res = await procedureApi.createCode({
          count: 1,
        });
        if (res) {
          this.$refs.baseForm.setForm({
            code: res[0],
          });
        }
      },
      async getProcedureIdProcess() {
        const res = await procedureApi.getProcedureIdProcess(this.params.id);
        if (res) {
          this.onlyProcessList = res.map((item) => {
            return {
              ...item,
              version: item.name,
            };
          });
        }
      },
      async submitFieldForm() {
        console.log("ok");
        let obj = {
          id: null,
          fieldCode: null,
          fieldName: this.addOrModifyField.fieldName,
          type: 5,
          isShow: false,
        };
        if (this.isAddField) {
          this.procedureFieldListCustom.push(obj);
        } else {
          console.log(this.procedureFieldListCustom)
          console.log(this.fieldIndex)
          this.procedureFieldListCustom[this.fieldIndex].fieldName =
            this.addOrModifyField.fieldName;
        }
        this.addOrModifyField.fieldName = "";
        this.fieldVisible = false;
      },
      deleteField(item, index) {
        this.procedureFieldListCustom.splice(index, 1);
      },
      addField(item, index) {
        if (item.fieldName) {
          this.addOrModifyField.fieldName = item.fieldName;
          this.isAddField = false;
        } else {
          this.isAddField = true;
        }
        if (index!== undefined) {
          this.fieldIndex = index;
        }
        this.fieldVisible = true;
        console.log("index:",index);
        console.log(this.procedureFieldListCustom);
      },
      async submitProcessForm(done) {
        let versionIds = "";
        if (this.tongRadio === 2) {
          const list = this.onlyProcess.map((item) => {
            return item[1];
          });
          versionIds = list.join("^");
        }
        const res = await procedureApi.synchronousToProcess(
          this.params.id,
          versionIds
        );
        if (res) {
          this.$message.success("同步成功");
          this.processVisible = false;
        }
        done();
      },
      async getQualityInspectionPlanList() {
        const res = await QIP.getInspectionPlanList(this.defaultSearch);
        if (res) {
          this.formOptions.inspectionPlanList = res;
        }
      },
      procedureTypeChange(val) {
        this.procedureType = val;
        if (val === 1) {
          this.qualityInspectionPlan.inspectionProjectGroupFirst = {
            isFullInspection: 1,
            isNeedInspect: 0,
            isMustCheck: 0,
            inspectId: null,
            inspectEntryMap: {},
            inspectAttachments: JSON.stringify([]),
            inspectorIdList: [],
          };
        } else {
          this.outsourceTime = 0;
        }
      },
      tabChange(tab) {},
      async qualityInspectionChange(id, key) {
        const res = await QIP.getInspectionPlanDetailById({ id });
        if (res) {
          // ================处理报检项信息========Start========
          if(false){
            if(res.inspectionObject){
              this.baojianMsg.topSelected = res.inspectionObject
            }
            if(res.whetherInspectionReport){
              this.baojianMsg.topChecked = true
            }
            if(res.inspectionReportEntryList && res.inspectionReportEntryList.length > 0){
              this.baojianMsg.bjList = res.inspectionReportEntryList.map(item => {
                const aaa = {
                  inspectionEntryName: item.inspectionEntryName,
                  parameterType: item.parameterType,
                  required: item.required,
                  // defaultValue: item.defaultValue,
                  // possibleValue: item.possibleValue || '',
                  defaultIsDynamic: item.defaultIsDynamic,
                  defaultDynamicHead: item.defaultDynamicHead,
                  defaultDynamicEntry: item.defaultDynamicEntry,
                  defaultDynamicItem: item.defaultDynamicItem,
                  defaultDynamicFilter: item.defaultDynamicFilter
                }
                //处理可能值
                if(item.parameterType === 0 || item.parameterType === 1){
                  aaa.possibleValue = ""
                }else {
                  aaa.possibleValue = JSON.parse(item.possibleValue)
                }
                //处理默认值
                if(item.parameterType === 3){
                  if(item.defaultValue){
                    aaa.defaultValue = JSON.parse(item.defaultValue)
                  }else {
                    aaa.defaultValue = []
                  }
                }else {
                  aaa.defaultValue = item.defaultValue
                }
                return aaa
              })
            }
          }
          // ================处理报检项信息========End========
          this.qualityInspectionPlan[key] = {
            isFullInspection: this.qualityInspectionPlan[key].isFullInspection,
            isMustCheck: this.qualityInspectionPlan[key].isMustCheck,
            isNeedInspect: 1,
            inspectId: id,
            inspectEntryMap: res.inspectionEntryMap,
            inspectAttachments: res.attachments,
            inspectorIdList: res.userList,
            isCheck: res.isCheck, //0关闭  1开启审核
            checkedIDList: res.checkedIDList, //审核人员id
            isApproved: res.isApproved, ///0关闭  1开启审批
            approverIdList: res.approverIdList, //审批人员id
          };
        }
      },
      isNeedQualityInspectionChange(val, key) {
        if (val === 0) {
          this.qualityInspectionPlan[key] = {
            isFullInspection: 1,
            isMustCheck: 0,
            isNeedInspect: val,
            inspectId: null,
            inspectEntryMap: {},
            inspectAttachments: JSON.stringify([]),
            inspectorIdList: [],
            isCheck: null, //0关闭  1开启审核
            checkedIDList: [], //审核人员id
            isApproved: null, ///0关闭  1开启审批
            approverIdList: [], //审批人员id
          };
        }
      },
      selectName(item) {
        this.$refs.baseForm.setForm({
          groupId: item.id,
          groupName: item.name,
        });
      },
      beforeUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
          this.$message.error("上传文件大小不能超过 20M!");
        }
        return isLt20M;
      },
      fixData(data) {
        return data.filter((item, index) => index < 5 || index === 6);
      },
      assingChanges(type) {
        if (type === 1) {
          this.$refs.baseForm.setForm({
            issueMode: this.selectParams.issueMode,
          });
        }
        if (type === 2) {
          this.$refs.baseForm.setForm({
            assignMode: this.selectParams.assignMode,
          });
        }
      },
      // 获取默认下发和指派方式
      async getIssueConfig() {
        const search = {
          searchVal: Encrypt(
            JSON.stringify([
              {
                id: getUUid(),
                fieldName: "code",
                fieldType: "string",
                fieldValue: "assign_mode^issue_mode",
                operator: "in",
              },
            ])
          ),
        };
        const res = await baseSettingApi.getDetail(search);
        if (res) {
          this.selectParams = {
            issueMode: parseInt(res[0].value),
            assignMode: parseInt(res[1].value),
          };
          this.$refs.baseForm.setForm({
            issueMode: parseInt(res[0].value),
            assignMode: parseInt(res[1].value),
          });
        }
      },
      async getStationList() {
        const res = await stationApi.getStationList(this.defaultSearch);
        if (res) {
          this.formOptions.stationOptions = res;
          this.formList[0].children[0].options = res;
        }
      },
      async getProcedureDetailById(id) {
        const res = await procedureApi.getProcedureDetail(id);
        if (res) {
          this.baseFormData = res;
          this.procedureType = res.type;
          this.outsourceTime = res.outsourceTime;
          this.stationList = res.stationList;
          this.operationDesc = res.operationDesc;
          this.drawingsList = res.drawing ? JSON.parse(res.drawing) : [];
          this.qualityInspectionPlan = {
            inspectionProjectGroupFirst: {
              isFullInspection: res.isFullInspection,
              isMustCheck: res.isMustCheck,
              isNeedInspect: res.isNeedFirstInspect,
              inspectId: res.firstInspectId,
              inspectEntryMap: res.firstInspectEntryMap || {},
              inspectAttachments: res.firstInspectAttachments,
              inspectorIdList: res.firstInspectorIdList,
            },
            inspectionProjectGroupProcess: {
              isFullInspection: res.isFullInspection,
              isMustCheck: res.isMustCheck,
              isNeedInspect: res.isNeedProcessInspect,
              inspectId: res.processInspectId,
              inspectEntryMap: res.processInspectEntryMap || {},
              inspectAttachments: res.processInspectAttachments,
              inspectorIdList: res.processInspectorIdList,
              isCheck: res.isCheck, //0关闭  1开启审核
              checkedIDList: res.checkedIDList, //审核人员id
              isApproved: res.isApproved, ///0关闭  1开启审批
              approverIdList: res.approverIdList, //审批人员id
            },
          };
          this.filesList = res.attachment ? JSON.parse(res.attachment) : [];
          this.groupList = res.params ? JSON.parse(res.params) : [];
          this.selectParams = {
            issueMode: res.issueMode,
            assignMode: res.assignMode,
          };
          this.differenceMinus = res.differenceMinus;
          this.differencePlus = res.differencePlus;
          this.differenceType =
            res.differenceType === -1 ? "" : res.differenceType;
          if (res.procedureFieldListCustom.length > 0) {
            this.procedureFieldListCustom = res.procedureFieldListCustom;
          }
          if (res.procedureFieldListPc.length > 0) {
            this.procedureFieldListPc = res.procedureFieldListPc;
          }
          if (res.procedureFieldListApp.length > 0) {
            this.procedureFieldListApp = res.procedureFieldListApp;
          }
        }
        if (this.params.type === "copy") await this.createProcedureCode();
      },

      switchOrSubmit() {
        if (this.saveButton) {
          this.submitForm();
        } else {
          this.params.type = "modify";
          this.saveButton = true;
        }
      },
      async synchronousToProcess() {
        this.onlyProcess = [];
        this.tongRadio = 1;
        this.processVisible = true;
      },
      getValue(row) {
        const result = this.baseFormData[row.prop];
        if (row.form && row.form.options && row.prop != 'workCenterName') {
          const current = row.form.options.find((item) => item.id === result);
          return current && current.name;
        }
        return result;
      },
      // 添加工位
      addStation() {
        this.formData.stationIds = this.stationList.map((item) => item.id);
        this.visible = true;
      },
      // 上传图纸/附件
      fileUploadChange(res, file) {
        if (res.errorCode === 0) {
          this.$message.success("上传成功");
          this[this.uploadKey].push({
            id: file.uid,
            name: file.name,
            url: res.result,
          });
        } else {
          this.$message.error(res.result);
        }
      },
      delFrontData(key, index) {
        this[key].splice(index, 1);
        this.$message.success("删除成功");
      },
      submitStationForm(callback) {
        const { form } = this.$refs.formBuild;
        this.$refs.formBuild.formRefs().validate(async (valid) => {
          if (valid) {
            this.stationList = this.formOptions.stationOptions.filter(
              (item) => form.stationIds.indexOf(item.id) !== -1
            );
            this.$message.success("添加成功");
            this.visible = false;
            callback(true);
          } else {
            callback();
          }
        });
      },
      submitForm() {
        this.buttonLoading = true;
        this.$refs.baseForm.setForm({
          differenceMinus: this.differenceMinus,
          differencePlus: this.differencePlus,
          differenceType: this.differenceType,
        });
        this.$refs.baseForm.formRefs().validate(async (valid) => {
          if (valid) {
            if (
              this.qualityInspectionPlan.inspectionProjectGroupProcess
                .isNeedInspect
            ) {
              if (
                !this.qualityInspectionPlan.inspectionProjectGroupProcess
                  .inspectId
              ) {
                this.buttonLoading = false;
                return this.$message.info("请选择过程检质检方案");
              }
              const projectProcessObject =
                this.$refs.qualityInspectionProjectProcess[0].transferDataToStandard();
              if (!projectProcessObject) {
                this.buttonLoading = false;
                return false;
              }
            }
            if (
              this.qualityInspectionPlan.inspectionProjectGroupFirst
                .isNeedInspect &&
              this.procedureType !== 1
            ) {
              if (
                !this.qualityInspectionPlan.inspectionProjectGroupFirst.inspectId
              ) {
                this.buttonLoading = false;
                return this.$message.info("请选择首检质检方案");
              }
              const projectFirstObject =
                this.$refs.qualityInspectionProjectFirst[0].transferDataToStandard();
              if (!projectFirstObject) {
                this.buttonLoading = false;
                return false;
              }
            }
            await this.addOrModify();
          } else {
            this.buttonLoading = false;
          }
        });
      },
      async addOrModify() {
        let res;
        const { form } = this.$refs.baseForm;
        if (["add", "copy"].indexOf(this.params.type) !== -1) {
          res = await procedureApi.addProcedure(this.createSetData(form));
        }
        if (this.params.type === "modify") {
          res = await procedureApi.modifyProcedure(this.createResetData(form));
        }
        if (res) {
          this.saveButton = false;
          this.$message.success(this.messages[this.params.type]);
          if (this.params.type !== "modify") {
            this.$router.push({ query: { type: "view", id: res } });
            this.params = { type: "view", id: res };
          }
          await this.getProcedureDetailById(this.params.id);
        }
        this.buttonLoading = false;
      },
      createSetData(raw) {
        console.log(raw);
        const groupList = this.memberType === 'line' ? this.$refs.procedureParams.paramsGroupList.map(
          (item) => ({
            name: item.name,
            formList: item.originFormList,
          })
        ) : [];
        const isNeedFirstInspect =
          this.qualityInspectionPlan.inspectionProjectGroupFirst.isNeedInspect &&
          this.procedureType !== 1
            ? 1
            : 0;
        const isNeedProcessInspect =
          this.qualityInspectionPlan.inspectionProjectGroupProcess.isNeedInspect;
        const isFullInspection =
          this.qualityInspectionPlan.inspectionProjectGroupProcess
            .isFullInspection;
        const isMustCheck =
          this.qualityInspectionPlan.inspectionProjectGroupProcess.isMustCheck;
        const projectProcessObject = isNeedProcessInspect
          ? this.$refs.qualityInspectionProjectProcess[0].transferDataToStandard()
          : {};
        const projectFirstObject = isNeedFirstInspect
          ? this.$refs.qualityInspectionProjectFirst[0].transferDataToStandard()
          : {};
        return {
          procedureFieldListPc: this.procedureFieldListPc,
          procedureFieldListApp: this.procedureFieldListApp,
          procedureFieldListCustom: this.procedureFieldListCustom,

          code: raw.code,
          name: raw.name,
          groupId: raw.groupId,
          groupName: raw.groupName,
          procedureClassId: raw.procedureClassId,
          procedureClassName: raw.procedureClassName,
          drawing: JSON.stringify(this.drawingsList || []),
          attachment: JSON.stringify(this.filesList || []),
          type: raw.type,
          outsourceTime: raw.type === 1 ? this.outsourceTime : 0,
          params: JSON.stringify(groupList),
          operationDesc: this.operationDesc,
          stationIdList: this.stationList.map((item) => item.id),
          isEnabled: raw.isEnabled,
          remark: raw.remark,
          issueMode: raw.issueMode,
          assignMode: raw.assignMode,
          differenceMinus: raw.differenceMinus,
          differencePlus: raw.differencePlus,
          differenceType: raw.differenceType,
          isNeedFirstInspect,
          firstInspectId: isNeedFirstInspect
            ? this.qualityInspectionPlan.inspectionProjectGroupFirst.inspectId
            : null,
          firstInspectAttachments: isNeedFirstInspect
            ? projectFirstObject.attachments
            : JSON.stringify([]),
          firstInspectorIdList: isNeedFirstInspect
            ? projectFirstObject.inspectorList
            : [],
          firstInspectEntryMap: isNeedFirstInspect
            ? projectFirstObject.inspectionEntryMap
            : {},
          isNeedProcessInspect,
          isFullInspection,
          isMustCheck,
          processInspectId: isNeedProcessInspect
            ? this.qualityInspectionPlan.inspectionProjectGroupProcess.inspectId
            : null,
          processInspectAttachments: isNeedProcessInspect
            ? projectProcessObject.attachments
            : JSON.stringify([]),
          processInspectorIdList: isNeedProcessInspect
            ? projectProcessObject.inspectorList
            : [],
          processInspectEntryMap: isNeedProcessInspect
            ? projectProcessObject.inspectionEntryMap
            : {},
          isCheck: isNeedProcessInspect ? projectProcessObject.isCheck : null, //0关闭  1开启审核
          checkedIDList: isNeedProcessInspect
            ? projectProcessObject.checkedIDList
            : [], //审核人员id
          isApproved: isNeedProcessInspect
            ? projectProcessObject.isApproved
            : null, ///0关闭  1开启审批
          approverIdList: isNeedProcessInspect
            ? projectProcessObject.approverIdList
            : [], //审批人员id
            workCenterId: raw.workCenterId
        };
      },
      createResetData(raw) {
        const obj = {
          id: raw.id,
        };
        return Object.assign(this.createSetData(raw), obj);
      },
      // async getProcedureFieldList(id) {
      //   const res = await procedureApi.getProcedureDetail(id);
      // }
    },
  };
</script>

<style lang="scss" scoped>
.baojian-container {
  width: 100%;
  overflow-x: scroll;
  max-height: 568px;
  border: 1px solid #ebeef5;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;

  .edd{
    position: absolute;
    right: 4px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .divItem {
    color: #909399;
    border-right: 1px solid #EBEEF5;
    border-bottom: 1px solid #EBEEF5;
    padding: 4px;
    background: #F6F7F9;
    line-height: 23px;
    font-weight: bold;
    font-size: 14px;
  }

  .divItemS {
    color: #606266;
    font-weight: normal;
  }
  .divItemSW {
    color: #606266;
    font-weight: normal;
    background: white;
  }

  .content1{
    flex: auto;
    min-width: 200px;
    box-sizing: border-box;
  }

}
  ::v-deep {
    .el-select-dropdown.el-popper.is-multiple.multi-cascader-style
    .li-style
    .li-label-style {
      color: #607fff;
    }
    .el-select-dropdown.el-popper.is-multiple.multi-cascader-style
    .li-style.active-li {
      background-color: rgba(96, 127, 255, 0.1);
      color: #607fff;
    }
  }
  .procedure-detail-wrapper {
    height: 100%;

    .procedure-detail-header {
      width: 100%;
      padding: 10px 0;
    }

    .procedure-detail-body {
      height: calc(100% - 60px);
      overflow-y: auto;

      .detail-card {
        margin-bottom: 20px;
        .card-bot-item {
          margin-bottom: 10px;
          .item-title {
            margin-bottom: 6px;
          }
          .fieldItem {
            margin-right: 30px;
          }
        }
        .typeTitle {
          margin-bottom: 8px;
        }
        .pcCard{
          padding: 10px;
          background-color: #f6f7f9;
          margin-bottom: 10px;
        }
      }
    }
  }
  .flex-mode {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    .tit {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      margin-right: 20px;
    }
    ::v-deep .el-radio-group {
      vertical-align: initial;
    }
    ::v-deep .el-radio {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456b;
    }
  }
  .flex-modes {
    display: inline-flex;
    justify-content: flex-start;
    margin-top: 20px;
    .tit {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      margin-right: 20px;
    }
    .mode-ri {
      .ri-top {
        display: flex;
        justify-content: flex-start;
        margin: 10px 0;
        .top-tit {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393d60;
          line-height: 36px;
        }
        .ri-num {
          width: 130px;
          margin: 0 20px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dde3eb;
        }
      }
    }
  }
  .bot-tis {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    display: inline-block;
  }
</style>
