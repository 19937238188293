var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrap-wrap" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "el-row",
            {
              staticStyle: {
                padding: "10px 10px 10px 10px",
                "border-bottom": "1px solid #dde3eb"
              }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "95%" },
                      attrs: { type: "text", placeholder: "工单编号" },
                      on: { change: _vm.topSearch },
                      model: {
                        value: _vm.topSearchForm.productionPlanNumber,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.topSearchForm,
                            "productionPlanNumber",
                            $$v
                          )
                        },
                        expression: "topSearchForm.productionPlanNumber"
                      }
                    },
                    [_vm._v("<")]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "95%" },
                      attrs: { type: "text", placeholder: "物料编码" },
                      on: { change: _vm.topSearch },
                      model: {
                        value: _vm.topSearchForm.materialsCode,
                        callback: function($$v) {
                          _vm.$set(_vm.topSearchForm, "materialsCode", $$v)
                        },
                        expression: "topSearchForm.materialsCode"
                      }
                    },
                    [_vm._v("<")]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "95%" },
                      attrs: { type: "text", placeholder: "物料名称" },
                      on: { change: _vm.topSearch },
                      model: {
                        value: _vm.topSearchForm.materialsName,
                        callback: function($$v) {
                          _vm.$set(_vm.topSearchForm, "materialsName", $$v)
                        },
                        expression: "topSearchForm.materialsName"
                      }
                    },
                    [_vm._v("<")]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "95%" },
                      attrs: { type: "text", placeholder: "焊缝号" },
                      on: { change: _vm.topSearch },
                      model: {
                        value: _vm.topSearchForm.weldNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.topSearchForm, "weldNumber", $$v)
                        },
                        expression: "topSearchForm.weldNumber"
                      }
                    },
                    [_vm._v("<")]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { background: "#607FFF" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.topSearch }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.resetTopSearch }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("FunctionHeader", {
            ref: "functionHeader",
            attrs: {
              "columns-key": "TTT-GYQD",
              "search-title": "请输入工单编号搜索",
              "import-name": "GYQDImport",
              tags: !_vm.exportSelectionIds.length
                ? _vm.functionHeader.tags
                : []
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              "columns-key": "TTT-GYQD",
              columns: _vm.columns,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c(
                    "div",
                    {},
                    [
                      _vm.permission("Edit")
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle("edit", row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            )
                          ]
                        : _vm._e(),
                      _vm.permission("Del")
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle("del", row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.currentType, width: "1000px", top: "30px" },
          on: { onOk: _vm.addOrEditSubmit },
          model: {
            value: _vm.visiable,
            callback: function($$v) {
              _vm.visiable = $$v
            },
            expression: "visiable"
          }
        },
        [
          _c(
            "h3",
            { staticStyle: { "margin-top": "0", "margin-bottom": "10px" } },
            [_vm._v("工单信息")]
          ),
          _c(
            "el-row",
            { staticStyle: { padding: "10px" }, attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*")
                      ]),
                      _vm._v("工单编号")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "98%" },
                            attrs: {
                              disabled: !!_vm.dataForAdd.id,
                              filterable: "",
                              placeholder: "工单编号"
                            },
                            on: { change: _vm.getGD },
                            model: {
                              value: _vm.dataForAdd.productionPlanNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.dataForAdd,
                                  "productionPlanNumber",
                                  $$v
                                )
                              },
                              expression: "dataForAdd.productionPlanNumber"
                            }
                          },
                          _vm._l(_vm.allGD, function(item) {
                            return _c("el-option", {
                              key: item.orderId,
                              attrs: {
                                label:
                                  item.orderCode +
                                  "-" +
                                  item.materialsCode +
                                  "-" +
                                  item.materialsName,
                                value: item.orderCode
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("物料编号")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { disabled: "", type: "text" },
                          model: {
                            value: _vm.dataForAdd.materialsCode,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "materialsCode", $$v)
                            },
                            expression: "dataForAdd.materialsCode"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("物料名称")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { disabled: "", type: "text" },
                          model: {
                            value: _vm.dataForAdd.materialsName,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "materialsName", $$v)
                            },
                            expression: "dataForAdd.materialsName"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "h3",
            { staticStyle: { "margin-top": "0", "margin-bottom": "10px" } },
            [_vm._v("焊缝信息")]
          ),
          _c(
            "el-row",
            { staticStyle: { padding: "10px" }, attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*")
                      ]),
                      _vm._v("焊缝号")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: {
                            disabled: !!_vm.dataForAdd.id,
                            type: "text"
                          },
                          model: {
                            value: _vm.dataForAdd.weldNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "weldNumber", $$v)
                            },
                            expression: "dataForAdd.weldNumber"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*")
                      ]),
                      _vm._v("管线号")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.pipelineNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "pipelineNumber", $$v)
                            },
                            expression: "dataForAdd.pipelineNumber"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("母材材质")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.baseMaterial,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "baseMaterial", $$v)
                            },
                            expression: "dataForAdd.baseMaterial"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("母材厚度")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.thickness,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "thickness", $$v)
                            },
                            expression: "dataForAdd.thickness"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("母材材质2")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.baseMaterialSecond,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.dataForAdd,
                                "baseMaterialSecond",
                                $$v
                              )
                            },
                            expression: "dataForAdd.baseMaterialSecond"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("母材厚度2")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.thicknessSecond,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "thicknessSecond", $$v)
                            },
                            expression: "dataForAdd.thicknessSecond"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-start",
                        "align-items": "center"
                      }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticStyle: {
                            width: "100px",
                            "text-align": "right",
                            "padding-right": "10px",
                            "line-height": "32px",
                            "font-size": "14px"
                          }
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*")
                          ]),
                          _vm._v("工艺规程编号 ")
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { flex: "1" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "95%" },
                              attrs: {
                                filterable: "",
                                placeholder: "工艺规程"
                              },
                              model: {
                                value: _vm.dataForAdd.weldProcessRegulationId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.dataForAdd,
                                    "weldProcessRegulationId",
                                    $$v
                                  )
                                },
                                expression: "dataForAdd.weldProcessRegulationId"
                              }
                            },
                            _vm._l(_vm.allGYGC, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.number, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("焊接方法")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.weldMethod,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "weldMethod", $$v)
                            },
                            expression: "dataForAdd.weldMethod"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [_vm._v("标准")]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.standard,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "standard", $$v)
                            },
                            expression: "dataForAdd.standard"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("外周长")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.outerPerimeter,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "outerPerimeter", $$v)
                            },
                            expression: "dataForAdd.outerPerimeter"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [_vm._v("直径")]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.diameter,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "diameter", $$v)
                            },
                            expression: "dataForAdd.diameter"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [_vm._v("长度")]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.length,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "length", $$v)
                            },
                            expression: "dataForAdd.length"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          ),
          !_vm.dataForAdd.id
            ? _c(
                "h3",
                { staticStyle: { "margin-top": "0", "margin-bottom": "10px" } },
                [_vm._v("审批信息")]
              )
            : _vm._e(),
          !_vm.dataForAdd.id
            ? _c(
                "el-row",
                { staticStyle: { padding: "10px" }, attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { span: 8 }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticStyle: {
                                width: "100px",
                                "text-align": "right",
                                "padding-right": "10px",
                                "line-height": "32px",
                                "font-size": "14px"
                              }
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*")
                              ]),
                              _vm._v("审批人 ")
                            ]
                          ),
                          _c(
                            "div",
                            { staticStyle: { flex: "1" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "95%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择审批人"
                                  },
                                  model: {
                                    value: _vm.dataForAdd.userName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.dataForAdd, "userName", $$v)
                                    },
                                    expression: "dataForAdd.userName"
                                  }
                                },
                                _vm._l(_vm.systemUsers, function(item) {
                                  return _c("el-option", {
                                    key: item.userName,
                                    attrs: {
                                      label: item.name,
                                      value: item.userName
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }