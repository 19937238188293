export const columns = [
  {
    prop: 'index',
    label: '序号',
    fixed: 'left',
    width: 80,
    slotName: 'index',
    hiddenSearch: true
  },
  {prop: 'approveStatusName', label: '审批状态', sortable: false, hiddenSearch: true},
  {prop: 'productionPlanNumber', label: '工单编号', sortable: false, hiddenSearch: true},
  {prop: 'materialsCode', label: '物料编号', sortable: false, hiddenSearch: true},
  {prop: 'materialsName', label: '物料名称', sortable: false, hiddenSearch: true},
  {prop: 'weldNumber', label: '焊缝号', sortable: false, hiddenSearch: true},
  {prop: 'pipelineNumber', label: '管线号', sortable: false, hiddenSearch: true},
  {prop: 'baseMaterial', label: '母材材质', sortable: false, hiddenSearch: true},
  {prop: 'thickness', label: '母材厚度', sortable: false, hiddenSearch: true},
  {prop: 'baseMaterialSecond', label: '母材材质2', sortable: false, hiddenSearch: true},
  {prop: 'thicknessSecond', label: '母材厚度2', sortable: false, hiddenSearch: true},
  {prop: 'weldProcessRegulationNumber', label: '焊接工艺规程编号', sortable: false, hiddenSearch: true},
  {prop: 'weldMethod', label: '焊接方法', sortable: false, hiddenSearch: true},
  {prop: 'standard', label: '标准', sortable: false, hiddenSearch: true},
  {prop: 'outerPerimeter', label: '外周长', sortable: false, hiddenSearch: true},
  {prop: 'diameter', label: '直径', sortable: false, hiddenSearch: true},
  {prop: 'length', label: '长度', sortable: false, hiddenSearch: true},
  {prop: 'statusName', label: '规程使用状态', sortable: false, hiddenSearch: true},
  {
    fixed: 'right',
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    slotName: 'action'
  }
]
