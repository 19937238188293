<template>
  <div :class="isHiddenLogo ? 'hiddenLogo login-container' : 'login-container'">
    <div class="login-left">

    </div>
    <div class="login-right">
      <div class="center">
        <div class="blank-placeholder" />
        <div class="form-wrapper">
          <div class="cac-logo">
            <img src="https://oss.mubyte.cn/fsd/index-img/fsd002.png">
          </div>
          <div class="login-tab">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="手机号登录" name="first">
                <div class="form" style="border-top-right-radius: 8px;">
                  <!-- <div class="title">欢迎使用，请登录</div> -->
                  <el-form ref="loginPhoneForm" :model="loginPhoneForm" :rules="loginPhoneRules" class="login-form"
                           label-position="left">
                    <div class="formLayout">
                      <el-form-item prop="phoneNumber">
                        <el-autocomplete ref="phone" v-model="loginPhoneForm.phone" class="inline-input"
                                         :fetch-suggestions="querySearch" value-key="phone" placeholder="请输入手机号" style="width: 100%"
                                         @select="handleSelect" tabindex="1" @keyup.tab.native="handlePass(2)" />
                      </el-form-item>
                      <el-form-item prop="password" style="padding-bottom: 0">
                        <el-input :key="passwordPhoneType" ref="password" v-model="loginPhoneForm.password"
                                  :type="passwordPhoneType" placeholder="请输入密码" name="password" tabindex="2"
                                  @keyup.enter.native="handlePhoneLogin" />
                        <span class="show-pwd" @click="showPwd">
                          <svg-icon :icon-class="passwordPhoneType === 'password' ? 'eye' : 'eye-open'" />
                        </span>
                      </el-form-item>
                    </div>
                    <el-form-item prop="remainPhonePassword" style="text-align: left;padding: 0;">
                      <el-checkbox v-model="loginPhoneForm.remainPhonePassword">记住账号</el-checkbox>
                      <DownCode></DownCode>
                    </el-form-item>
                    <el-button :loading="loading" type="primary" style="width:100%;color: #fff"
                               @click.native.prevent="handlePhoneLogin">立即登录
                    </el-button>
                  </el-form>
                </div>
              </el-tab-pane>
              <el-tab-pane label="工厂账号登录" name="second">
                <div class="form" style="border-top-left-radius: 8px;">
                  <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form"
                           label-position="left">
                    <el-form-item prop="memberCode" v-if="!isSign">
                      <el-autocomplete ref="memberCode" v-model="loginForm.memberCode" class="inline-input"
                                       :fetch-suggestions="querySearchM" value-key="memberCode" placeholder="请输入工厂代码" style="width: 100%"
                                       @select="handleSelect" tabindex="1" @keyup.tab.native="handlePass(1)" />
                    </el-form-item>
                    <div :class="isSign ? 'formLayout' : ''">
                      <el-form-item prop="userName">
                        <el-autocomplete ref="userName" v-model="loginForm.userName" class="inline-input"
                                         :fetch-suggestions="querySearch" value-key="userName" placeholder="请输入用户名" style="width: 100%"
                                         @select="handleSelect" tabindex="2" @keyup.tab.native="handlePass(2)" />
                      </el-form-item>
                      <el-form-item prop="password" style="padding-bottom: 0">
                        <el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType"
                                  placeholder="请输入密码" name="password" tabindex="3" @keyup.enter.native="handleLogin" />
                        <span class="show-pwd" @click="showPwd">
                          <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
                        </span>
                      </el-form-item>
                    </div>
                    <el-form-item prop="remainPassword" style="text-align: left;padding: 0">
                      <el-checkbox v-model="loginForm.remainPassword">记住账号</el-checkbox>
                      <DownCode  v-if="!isSign"></DownCode>
                    </el-form-item>
                    <el-button :loading="loading" type="primary" style="width:100%;color: #fff"
                               @click.native.prevent="handleLogin">立即登录
                    </el-button>
                  </el-form>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <div class="bottom-txt">©2015-{{nowYear}} 木白科技.保留所有权利 沪ICP备16027431号-5</div>
    </div>

    <div class="owner">
      <!-- <div class="footer">
        <img v-if="!isHiddenLogo" src="https://oss.mubyte.cn/static/login-bkg.png" class="imgs" @click="jumps">
      </div> -->
    </div>
  </div>
</template>

<script>
import server from '@/api/user'
import { cloneDeep } from 'lodash'
import { Encrypt } from '@/utils/sercet'
import { getToken, setToken, getVersionMsg, setVersionMsg } from '@/utils/auth'
import { getFirstRouterName } from '@/utils'
import api from '@/api/sets/base-setting'
import DownCode from './downCode'
import baseSetting from '@/api/sets/base-setting'
import state from '@/components/printDesigner/store/state'
import store from '@/store'

export default {
  name: 'Login',
  components: { DownCode },
  data() {
    return {
      nowYear:"",
      typingTexts: [
        '提供优化的生产排程',
        '提供生产操作指导',
        '实现生产进度自动统计',
        '实现工人绩效准确统计',
        '实现物料的精细化管理',
        '提供完整质量追溯报告',
        '实现设备数据自动采集',
        '实现设备自动点检维护'
      ],
      // logoImg: '',
      activeName: 'second',
      loginPhoneForm: {
        phone: '',
        password: '',
        remainPhonePassword: false
      },
      loginForm: {
        memberCode: '',
        userName: '',
        password: '',
        remainPassword: false
      },
      // 手机号登录规则
      loginPhoneRules: {
        phone: [{ required: true, trigger: 'blur', message: '请输入手机号' }],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }]
      },
      loginRules: {
        memberCode: [{ required: true, trigger: ['blur', 'change'], message: '请输入工厂代码' }],
        userName: [{ required: true, trigger: ['blur', 'change'], message: '请输入用户名' }],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }]
      },
      loading: false,
      passwordPhoneType: 'password',
      passwordType: 'password',
      redirect: undefined,
      querySearchPhoneList: [],
      querySearchList: [],
      isHiddenLogo: false,
      isSign: false
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    this.nowYear = new Date().getFullYear()
    // localStorage.clear()
    sessionStorage.clear()
  },
  mounted() {
    if (process.env.VUE_APP_SIGN_MEMBER === 'reagold') {
      this.loginForm.memberCode = 'reagold'
      this.isSign = true
    }
    if (process.env.VUE_APP_SIGN_MEMBER === 'smvic') {
      this.loginForm.memberCode = 'smvic'
      this.isSign = true
    }
    // this.getDetails()
    try {
      const querySearchList = localStorage.getItem('querySearchList')
      if (querySearchList) {
        this.querySearchList = JSON.parse(querySearchList)
      }
    } catch (e) {
      console.error('错误存储')
    }
    try {
      const querySearchPhoneList = localStorage.getItem('querySearchPhoneList')
      if (querySearchPhoneList) {
        this.querySearchPhoneList = JSON.parse(querySearchPhoneList)
      }
    } catch (e) {
      console.error('错误存储')
    }
  },
  methods: {
    jumps() {
      window.open('http://mubyte.cn/')
    },
    // tab切换样式
    handleClick() {

    },
    getMemberCode() {
      // const baseCode = await baseSetting.getCode({ code: 'member_code' })
      const baseCode = this.loginForm.memberCode
      if (baseCode) {
        const code = ['mubyte', 'c1', 'c2', 'c3', 'c4', 'c5', 'c6'].includes(baseCode) ? 'mubai' : baseCode
        localStorage.setItem('memberCode', code)
        if (baseCode === 'mubai') {
          const HL = this.$route.query.HL || localStorage.getItem('HL')
          if (HL === '0') {
            localStorage.removeItem('HL')
          } else {
            localStorage.setItem('HL', HL)
          }
        }
        this.isHiddenLogo = localStorage.getItem('HL') === '1'
        this.$forceUpdate()
      }
    },
    handlePass(val) {
      if (val === 1) {
        this.$refs.password.focus()
      } else if (val === 2) {
        this.$refs.userName.focus()
      }
    },
    // 获取优先级
    async getPlanConfig() {
      const res = await api.getCode({ code: 'plan_config' })
      if (res) {
        this.priorityStatus = res.value ? JSON.parse(res.value) : []
      }
    },
    // 获取logo
    // async getDetails() {
    //   const res = await api.getCode({ code: 'companyLogo' })
    //   if (res) {
    //     this.logoImg = res.isShow === 1 ? res.value : ''
    //   }
    // },
    showPwd() {
      if (this.activeName == 'second') {
        if (this.passwordType === 'password') {
          this.passwordType = ''
        } else {
          this.passwordType = 'password'
        }
      } else {
        if (this.passwordPhoneType === 'password') {
          this.passwordPhoneType = ''
        } else {
          this.passwordPhoneType = 'password'
        }
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 手机号登录
    handlePhoneLogin() {
      this.$refs.loginPhoneForm.validate(async valid => {
        if (valid) {
          this.loading = true
          const loginPhoneForm = cloneDeep(this.loginPhoneForm)
          loginPhoneForm.password = Encrypt(loginPhoneForm.password)
          this.loading = false
          return this.$message.error('所在工厂未开通手机号登录')
          // const res = await server.phoneLogin(loginPhoneForm)
          // if (res) {
          //   this.getMemberCode()
          //   setToken(res.token)
          //   if (this.loginPhoneForm.remainPhonePassword) {
          //     const isHad = this.querySearchPhoneList.find(item => item.phoneNumber === this.loginPhoneForm.phoneNumber)
          //     if (!isHad) {
          //       this.querySearchPhoneList.push(this.loginPhoneForm)
          //       localStorage.setItem('querySearchPhoneList', JSON.stringify(this.querySearchPhoneList))
          //     }
          //   } else {
          //     this.querySearchPhoneList = this.querySearchPhoneList.filter(item => item.phoneNumber !== this.loginPhoneForm.phoneNumber)
          //     localStorage.setItem('querySearchPhoneList', JSON.stringify(this.querySearchPhoneList))
          //   }
          //   await this.$store.dispatch('user/getInfo')
          //   await this.$store.dispatch('user/priorityStatusList')
          //   if (this.redirect) {
          //     this.$router.push({ path: this.redirect })
          //   } else {
          //     this.$router.push({ name: getFirstRouterName() })
          //   }
          // }
        }
      })
    },
    // 账号登录
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          localStorage.setItem('memberCode', this.loginForm.memberCode)
          this.loading = true
          const loginForm = cloneDeep(this.loginForm)
          loginForm.password = Encrypt(loginForm.password)
          const res = await server.login(loginForm)
          if (res) {
            // 处理缓存信息 tenantType  区分产线还是装备云 ————————Start————————
            const tenantType = res?.tenantInfo?.tenantType;
            localStorage.setItem('memberType', ['mom','line'].includes(tenantType) ? tenantType : '-租户类型异常—');
            // 处理缓存信息 tenantType  区分产线还是装备云 ————————End————————

            // 登录成功需要处理一下版本信息 存缓存 ————————Start————————
            const versionMsg = await server.getVersionMsg();
            if(versionMsg){
              setVersionMsg(versionMsg.value);
            }else {
              setVersionMsg('版本信息-error');
            }
            // 登录成功需要处理一下版本信息 存缓存 ————————End————————
            this.getMemberCode()
            setToken(res.token)
            if (this.loginForm.remainPassword) {
              const isHad = this.querySearchList.find(item => item.userName === this.loginForm.userName)
              if (!isHad) {
                this.querySearchList.push(this.loginForm)
                localStorage.setItem('querySearchList', JSON.stringify(this.querySearchList))
              }
            } else {
              this.querySearchList = this.querySearchList.filter(item => item.userName !== this.loginForm.userName)
              localStorage.setItem('querySearchList', JSON.stringify(this.querySearchList))
            }
            await this.$store.dispatch('user/getInfo')
            await this.$store.dispatch('user/priorityStatusList')
            await this.$store.dispatch('app/changeMode')
            let wepAuth = store.state.user.userInfo.authority
            // 处理单人员多角色 权限合并 ————————Start————————
            {
              wepAuth = wepAuth.split("|||")
              const aSet = new Set()
              wepAuth.forEach(item =>{
                if(item){
                  item = JSON.parse(item)
                  if(item.checked && item.checked.length > 0){
                    item.checked.forEach(p => aSet.add(p))
                  }
                  if (item.half && item.half.length > 0){
                    item.half.forEach(p => aSet.add(p))
                  }
                }
              })
              var tempObj = {
                checked: Array.from(aSet),
                half: []
              }
              wepAuth = JSON.stringify(tempObj)
            }
            // 处理单人员多角色 权限合并 ————————End————————
            const authListAll = JSON.parse(wepAuth)
            let authList = [...authListAll.checked, ...authListAll.half]
            authList = authList.filter(item => ['HomeWorkSpace','HomePersonalWorkbench','HomeException','HomeWorkHoursStatistic','OrderHoursStatistic','HomeAbnormalStatistics'].includes(item))
            if (this.loginForm.memberCode === 'xwhj-sh') {
              this.$router.push({ name: 'demandCenterList' })
            } else if (this.loginForm.memberCode === 'yyhl') {
              // 易易互联默认跳转生产工单列表页面
              this.$router.push({ name: 'prodPlan' })
            } else if (this.redirect) {
              if(this.redirect === '/home/fun'){
                if(authList.length){
                  this.$router.push({ name: authList[0] })
                }else {
                  this.$router.push({ path: '/home/fun/workspace' })
                }
              }else {
                this.$router.push({ path: this.redirect })
              }
            } else {
              this.$router.push({ name: getFirstRouterName() })
            }
          }
          this.loading = false
        }
      })
    },
    // 按手机号或用户名筛选下拉框
    querySearch(queryString, cb) {
      let restaurants, results
      if (this.activeName == 'first') {
        restaurants = this.querySearchPhoneList
        results = queryString ? restaurants.filter(this.createFilterPhone(queryString)) : restaurants
      } else {
        restaurants = this.querySearchList
        results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilterPhone(queryString) {
      return (restaurant) => {
        return (restaurant.phoneNumber.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.userName.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    // 按工厂代码筛选下拉框
    querySearchM(queryString, cb) {
      const restaurants = this.querySearchList
      const results = queryString ? restaurants.filter(this.createFilterM(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilterM(queryString) {
      return (restaurant) => {
        return (restaurant.memberCode.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect(val) {
      if (this.activeName == 'first') {
        this.loginPhoneForm = this._.cloneDeep(val)
      } else {
        this.loginForm = this._.cloneDeep(val)
      }

    }
  }
}
</script>

<style lang="scss">
.login-container {
  .el-form-item {
    // width: 314px;
    padding-bottom: 20px;
    position: relative;

    .el-input {
      input {
        border: none;
        border-bottom: 1px solid $border-color;
        border-radius: 0;
        padding-left: 0;
        transition: all .5s;

        &:focus {
          border-color: #0760A8;
        }
      }
    }

    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: $border-color;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .vue-typer .custom.char.typed {
  color: #fff;
}
::v-deep .vue-typer .custom.caret.typing {
  background-color: transparent;
}
::v-deep .vue-typer .custom.char.selected {
  background-color: transparent;
}
::v-deep .vue-typer .custom.char.typed {
  color: #fff;
}
::v-deep .vue-typer .custom.caret {
  background-color: #fff;
}
.login-container {
  width: 100%;
  height: 100%;
  background: url("https://oss.mubyte.cn/fsd/index-img/fsd003.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .login-left {
    // display: flex;
    // align-items: center;
    width: calc(100% - 600px);
    margin-left: 60px;
    .left-tit {
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 42px;
      text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
      display: inline-flex;
      height: 42px;
      align-items: center;
      margin-bottom: 12px;
      .arror-img {
        width: 20px;
        height: 20px;
        margin-left: 15px;
        cursor: pointer;
      }
    }
    .left-type {
      // height: 106px;
      font-size: 65px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 65px;
      letter-spacing: 1px;
      text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
      margin-bottom: 12px;
    }
    .left-txt {
      // height: 52px;
      font-size: 35px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 40px;
      letter-spacing: 2px;
      text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
    }
    .left-ul {
      display: flex;
      justify-content: flex-start;
      margin: 37px 0 56px 0;
      text-align: center;
      .left-ul-li {
        width: 170px;
        height: 65px;
        background: url("https://oss.mubyte.cn/static/logo-icon.png") no-repeat;
        background-size: 100% 100%;
        margin-right: 38px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 65px;
        letter-spacing: 1px;
      }
    }
    .left-bottom {
      width: 172px;
      text-align: center;
      .wechat-img {
        width: 172px;
        height: 172px;
        display: block;
        margin-bottom: 6px;
      }
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 25px;
      letter-spacing: 1px;
    }
  }
  .login-right {
    background: rgba(3, 13, 32, .6);
    width: 600px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .bottom-txt {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      position: absolute;
      bottom: 10px;
      width: 100%;
      text-align: center;
    }
  }
  .center {
    width: 415px;
    height: 510px;

    .blank-placeholder {
      width: 100%;
      height: 1px;
    }

    .form-wrapper {
      .cac-logo {
        text-align: center;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(-10px);

        img {
          width: 200px;
          display: block;
        }
      }

      .form {
        background: #FFFFFF;
        // box-shadow: 0 2px 8px 0 rgba(48, 56, 77, 0.2);
        padding: 35px 40px;
        height: 360px;

        .title {
          font-size: 18px;
          font-weight: 600;
          padding-bottom: 40px;
        }
        .formLayout{
          height: 170px;
          vertical-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      button {
        background: linear-gradient(250deg, #0707E6 0%, #2A05A5 100%);
      }
    }
  }

  .footer {
    margin-bottom: -16px;

    .imgs {
      width: 463px;
      height: 72px;
      display: inline-block;
      cursor: pointer;
    }
  }

  .owner {
    position: fixed;
    flex-direction: column;
    text-align: center;
    display: flex;
    bottom: 15px;
    width: 100%;
  }

}

.hiddenLogo {
  background: #f4faff !important;
}

.login-tab {
  background: #344271;
  box-shadow: 0 2px 8px 0 rgba(48, 56, 77, 0.2);
  border-radius: 8px;

  // padding: 63px 59px;
  ::v-deep .el-tabs {
    .el-tabs__header {
      margin: 0 auto;

      .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {
          // background:#edf1ff;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          .el-tabs__nav {
            width: 100%;

            .el-tabs__active-bar {
              display: none;
            }

            .el-tabs__item {
              display: inline-flex;
              justify-content: center;
              width: 50%;
              padding: 0;
              text-align: center;
              color: #a9abc2;
            }

            .el-tabs__item.is-active {
              color: #000;
              background-color: #ffffff;
              // box-shadow: 0 0 10px #bcc0de;
            }

            #tab-first::after {
              content: "";
              width: 0;
              height: 0;
              position: absolute;
              left: 207px;
              top: -2px;
              border-bottom: solid 15px transparent;
              border-right: 30px solid transparent;
              border-bottom: 42px solid #ffffff;

            }

            #tab-second::after {
              content: "";
              width: 0;
              height: 0;
              position: absolute;
              right: 207px;
              top: -2px;
              border-bottom: solid 15px transparent;
              border-left: 30px solid transparent;
              border-bottom: 42px solid #ffffff;

            }
          }
        }
      }
    }

    .el-tabs__nav-wrap::after {
      display: none;

    }

  }

  .popover-class {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
